import { Box, Container, Stack } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import DataCard from 'components/shared/dataCard/DataCard';
import CategoryAssignments from 'components/_dashboard/generalDashboard/CategoryAssignments';
import ComingAppoinments from 'components/_dashboard/generalDashboard/ComingAppoinments';
import LawyersAssignments from 'components/_dashboard/generalDashboard/LawyersAssignments';
import StatisticsInfo from 'components/_dashboard/generalDashboard/StatisticsInfo';
import TotalAppoinments from 'components/_dashboard/generalDashboard/TotalAppoinments';
import TotalIncome from 'components/_dashboard/generalDashboard/TotalIncome';
import useSettings from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {  getAppointmentCategoryAssignments, getTotalIncome, getTotalIncomeByYear } from 'redux/slices/report';

import {
  cardsData,
  categoryAssignments,
  incomeData,
  TABLE_HEAD,
} from './mockData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useLocales from 'hooks/useLocales';
import RequestWithInvoice from 'components/_dashboard/generalDashboard/RequestWithInvoice';

export default function AdminGeneralDahboard() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const [selected, setSelected] = useState<number>(2);
  
  const { AppointmentCategoryAssignments,isLoading , TotalIncomes, TotalIncomesByYear } = useSelector(
    (state: RootState) => state.report
  );
  var date = new Date();

  useEffect(() => {
    dispatch(getAppointmentCategoryAssignments()); 
    dispatch(getTotalIncome()); 
    dispatch(getTotalIncomeByYear(date.getFullYear()-1)); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch]);

  return (
    <Page title="الرئيسية| Shwra">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading={translate("main")} links={[{ name: '', href: '' }]} />
        <Box
          sx={{
            display: 'flex',
            marginBottom: '10px',
            [theme.breakpoints.down('lg')]: { flexWrap: 'wrap' },
            [theme.breakpoints.up('lg')]: { flexWrap: 'nowrap' }
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.up('lg')]: { width: '40%' },
              [theme.breakpoints.down('lg')]: { width: '100%' },
              marginRight: 'auto'
            }}
          >
            <Box sx={{ marginBottom: '20px' }}>
              <TotalAppoinments />
            </Box>
            <Box sx={{ marginBottom: '20px' }}>
              <DataCard title={translate('upcoming appointments')}>
                <ComingAppoinments  />
              </DataCard>
            </Box>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up('lg')]: { width: '70%', marginLeft: '20px' },
              [theme.breakpoints.down('lg')]: { width: '100%', marginBottom: '20px' }
            }}
          >
            <DataCard
              sx={{
                marginBottom: '20px'
              }}
            >
              <StatisticsInfo cardsData={cardsData} />
            </DataCard>
            
            <Stack gap={3} flexDirection={{ xs:'column',md:'row' }}>
            <DataCard
            title={translate('Request with Invoice')}
              sx={{
                // display: 'flex',
                // [theme.breakpoints.down('lg')]: { flexWrap: 'wrap' },
                // [theme.breakpoints.up('lg')]: { flexWrap: 'nowrap' },
                // justifyContent: 'space-between',
                // gap:8
                flex:1
              }}
            > 

               <RequestWithInvoice referenceType={1}/>



              {/* <TotalIncome
                incomeData={incomeData}
                selected={selected}
                setSelected={setSelected}
                total={TotalIncomes}
                TotalIncomesByYear={TotalIncomesByYear}
              /> */}
            </DataCard>
            <DataCard title={translate('Appointments with Invoices')} sx={{
              flex:1
            }}>
            <RequestWithInvoice referenceType={0}/>

            </DataCard>
            </Stack>
          </Box>
        </Box>

        {/* second row */}
        <Box
          sx={{
            display: 'flex',
            marginBottom: '30px',
            [theme.breakpoints.down('lg')]: { flexWrap: 'wrap' },
            [theme.breakpoints.up('lg')]: { flexWrap: 'nowrap' }
          }}
        >
          <DataCard
            title={translate('appointments of lawyers')}
            sx={{
              [theme.breakpoints.up('lg')]: { width: '70%' },
              [theme.breakpoints.down('lg')]: { width: '100%', marginBottom: '20px' }
            }}
          >
            <LawyersAssignments TABLE_HEAD={TABLE_HEAD} />
          </DataCard>
          <DataCard
            title={translate('assignments by category')}
            sx={{
              [theme.breakpoints.up('lg')]: { width: '30%', marginLeft: '20px' },
              [theme.breakpoints.down('lg')]: { width: '100%', marginBottom: '30px' }
            }}
          >
            <CategoryAssignments categoryAssignments={categoryAssignments} isLoading={isLoading} data={AppointmentCategoryAssignments}/>
          </DataCard>{' '}
        </Box>
      </Container>
    </Page>
  );
}
