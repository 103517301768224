// material
import { TableRow, TableCell, TableHead, Checkbox ,TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

type MusherListHeadProps = {
  rowCount: number;
  headLabel: any[];
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasSort?: boolean;
  orderBy?: any;
  order?: any;
  handleRequestSort?: (isAsc: any, property: any) => void;

};

export default function MusherListHead({ rowCount, headLabel, numSelected, onSelectAllClick,  hasSort,order,
  orderBy,handleRequestSort }: MusherListHeadProps) {
  
    const { translate } = useLocales();
      return (
    <TableHead>
      <TableRow sx={{ width:'10px' }}>
      <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            //sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
            <TableSortLabel
              // hideSortIcon
              // active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={(e) => handleRequestSort &&
                handleRequestSort(orderBy === headCell.id && order === 'asc', headCell.id)}            >
          
            {translate(headCell.label)}

            
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            ) : (
              translate(headCell.label)
            )}
             
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
