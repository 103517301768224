import React from 'react'
import { CSVLink } from 'react-csv';  
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import useLocales from 'hooks/useLocales';


type TableDownloaderProps = {
    disabled:boolean;
    data?:any;
    headers?:any;
    filename?:string;
}
const TableDownloader = ({ disabled,data='',headers=[],filename='' } : TableDownloaderProps ) => {

    const StyledCSVLink = styled(CSVLink)(
         
        {
        color: disabled===true ? '#7F828D' : '#fff',
        backgroundColor: disabled===true ? '#D8DAE0' : '#DDB669' , 
        pointerEvents: disabled===true ? 'none' : 'auto',  
        padding:0,
        border:'none',
        borderRadius: 4,
        display:'flex', 
        direction:'rtl',
        justifyContent:'center',
        alignItems:'center',
        width:'122px',
        height:'38px',
        gap:'.3rem'
      }
      );

     

const { translate } = useLocales();
      
  return (
     <StyledCSVLink  data={data} headers={headers}  filename={filename}> 
        <Icon fontSize={'1.5rem'} icon="material-symbols:download-rounded" />
          <span>
            {translate('download')}
          </span> 
      </StyledCSVLink>
  )
}

export default TableDownloader