// material
import { TableRow, TableCell, TableHead, TableSortLabel, Box ,Checkbox } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

type TicketListHeadProps = {
  headLabel: any[];
  hideSortIcon?: boolean;
  handleRequestSort?: (isAsc: any, property: any) => void;
  hasSort?: boolean;
  orderBy?: any;
  order?: any;
};

export default function RequestListHead({
  headLabel,
  hasSort,
  handleRequestSort,
  order,
  orderBy,
}: TicketListHeadProps) {

  const { translate } = useLocales();
  return (
    <TableHead sx={{ 
        backgroundColor: '#212B36', // Set your desired background color here
        color: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 1, // Ensure it stays above other content
      }} >
      <TableRow sx={{ width:'10px' }} >
      

        {headLabel.map((headCell) => {
          return (
            <TableCell 
            sx={{ padding:'10px 1px' }}
              key={headCell.id}
              align={ headCell.alignRight ? 'right' : 'left' }
              sortDirection={ orderBy === headCell.id ? order : false }
            >
              {hasSort ? (
                <TableSortLabel 
                  // active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={(e) => handleRequestSort &&
                    handleRequestSort(orderBy === headCell.id && order === 'asc', headCell.id)}
                >
                  {translate(headCell.label)}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                translate(headCell.label)
              )}


            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
