export const typesOptions = [
  { value: 0, label: 'موقع الكترونى' },
  { value: 1, label: 'تطبيق موبايل' },
  { value: 2, label: 'الاثنان معا' }
];

export const genderOptions = [
  { value: 0, label: 'ذكر' },
  { value: 1, label: 'انثى' }
];

export const classOptions = [
  { id: 1, name: 'Class A' },
  { id: 2, name: 'Class B' },
  { id: 3, name: 'Class C' },
];


export const masterRequestStatusOptions = [
  'New',
  'UnderProcessing',
  'Completed',
  'Canceled',
  'Approved',
  'Expired'
];
