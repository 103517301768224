import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton,
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  TextField,
  InputAdornment,
  Checkbox,
  Tooltip
} from '@mui/material';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import LoadingTable from '../../../components/LoadingTable';
import EmptyTable from '../../../components/EmptyTable';
import 'moment/locale/ar';
import Label from 'components/Label';
import { useTheme } from '@mui/material/styles';
import HeaderWithoutTabs from 'components/_dashboard/HeaderWithoutTabs';
import { AccessTime, CheckCircleRounded, Clear, Done, PendingRounded } from '@mui/icons-material';
import TableDownloader from '../TableDownloader';
import { CircularProgress } from '@mui/material';
//icons
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  getMusherList,
  setUpdateMusherStatus,
  updateBulkMusherAvailability,
  updateMusherAvailability
} from 'redux/slices/musher';
import { IMusherList } from '../../../@types/musher';
import MusherListHead from './MusherListHead';
import MusherTableToolbar from './MusherTableToolbar';
import usePageFilter from 'hooks/usePageFilter';
import { PATH_DASHBOARD } from 'routes/paths';
import moment from 'moment';
import useLocales from 'hooks/useLocales';
import useAuth from 'hooks/useAuth';
import { getAllowedActionsByModuleName } from 'redux/slices/permission';

const TableLink = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

const TABLE_HEAD = [
  { id: 'number', label: 'number', alignRight: false },
  { id: 'firstName', label: 'first name', alignRight: false },
  { id: 'lastName', label: 'last name', alignRight: false },
  { id: 'email', label: 'email', alignRight: false },
  { id: 'phone', label: 'phone number', alignRight: false },
  { id: 'createdDate', label: 'created date', alignRight: false, sort: true },
  { id: 'status', label: 'status', alignRight: false },
  { id: 'availability', label: 'availability', alignRight: false },
  { id: '', alignRight: false }
];

export default function MusherList() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, setPage, statusFilter, setStatusFilter } = usePageFilter();
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<readonly string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { musherStatus, musherList, isLoading, musherAvailabilityStatus } = useSelector(
    (state: RootState) => state.musher
  );
  const musherAvailability = useSelector((state: RootState) => state.availability?.rules);
  const [mushers, setMushers] = useState<IMusherList>({
    totalCount: 0,
    data: [],
    page: 0,
    size: 0
  });

  const [changingAvailability, setChangingAvailability] = useState(false);
  const [changingAvailID, setChangingAvailD] = useState<any>(null);
  // to reload musher list after status change
  const [trackerForReload, setTrackerForReload] = useState(false);
  const [searchInput, setSearchInput] = useState<any>(null);
  const [order, setOrder] = useState<'asc' | 'desc' | ''>('');
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const handleRequestSort = (isAsc: any, property: any) => {
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (musherList) {
      setMushers({
        ...musherList,
        data: musherList?.data
      });
    }
  }, [musherList]);

  //-------------------------------------------

  const sendRequest = () => {
    dispatch(
      getMusherList({
        page: page + 1,
        size: rowsPerPage,
        userGPTStatus: statusFilter,
        UserPhoneNum: searchInput ? searchInput : null,
        OrderByColumn: 'CreatedDate',
        OrderByDesc: order === 'asc' ? false : true
      })
    );
  };

  useEffect(() => {
    if (searchInput) {
      dispatch(
        getMusherList({
          page: null,
          size: null,
          userGPTStatus: null,
          UserPhoneNum: searchInput ? searchInput : null,
          OrderByColumn: 'CreatedDate',
          OrderByDesc: order === 'asc' ? false : true
        })
      );
    } else
      dispatch(
        getMusherList({
          page: page + 1,
          size: rowsPerPage,
          userGPTStatus: statusFilter,
          UserPhoneNum: searchInput ? searchInput : null,
          OrderByColumn: 'CreatedDate',
          OrderByDesc: order === 'asc' ? false : true
        })
      );
  }, [dispatch, searchInput, page, rowsPerPage, statusFilter, trackerForReload, order]);

  useEffect(() => {
    if (musherStatus?.success) {
      dispatch(
        getMusherList({
          page: page + 1,
          size: rowsPerPage,
          userGPTStatus: statusFilter,
          UserPhoneNum: null,
          OrderByColumn: 'CreatedDate',
          OrderByDesc: order === 'asc' ? false : true
        })
      );
      dispatch(setUpdateMusherStatus({}));
    }
  }, [dispatch, musherStatus, page, rowsPerPage, statusFilter, order]);

  const isMusherEmpty = !isLoading && mushers?.data?.length === 0;

  //Design for different rows
  const RowDesignA = {
    bgcolor: theme.palette.mode === 'dark' ? '#161C24' : '#ffe9dc',
    '&.MuiTableRow-hover:hover': { bgcolor: theme.palette.mode === 'dark' ? '#29343F' : '#F6F7F8' }
  };

  // to change musher availability status

  const handleClickSerachAction = () => {
    if (searchInput) {
      setSearchInput('');
    } else {
      return;
    }
  };

  const AvailabilityHandler = (id: string) => {
    setChangingAvailability(true);
    setChangingAvailD(id);
    dispatch(updateMusherAvailability({ UserId: id })).then(() => {
      setChangingAvailability(false);
      setChangingAvailD(null);
      setTrackerForReload(!trackerForReload);
      setSelected([]);
      // handleClickSerachAction();
    });
  };

  const AvailabilityBulkHandler = (id: Array<string>) => {
    setChangingAvailability(true);
    setChangingAvailD(id);
    dispatch(updateBulkMusherAvailability({ UsersId: id })).then(() => {
      setChangingAvailability(false);
      setChangingAvailD(null);
      setTrackerForReload(!trackerForReload);
      setSelected([]);
      // handleClickSerachAction();
    });
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = mushers?.data.map((n) => n.phoneNumber);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string, status: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    let newSelectedStatus: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
      newSelectedStatus = newSelectedStatus.concat(selectedStatus, status);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedStatus = newSelectedStatus.concat(selectedStatus.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedStatus = newSelectedStatus.concat(selectedStatus.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedStatus = newSelectedStatus.concat(
        selectedStatus.slice(0, selectedIndex),
        selectedStatus.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelectedStatus(newSelectedStatus);
  };

  const { user } = useAuth();
  const { actionsList } = useSelector((state: RootState) => state.permission);

  useEffect(() => {
    dispatch(getAllowedActionsByModuleName(user?.id, 'adviser'));
  }, [dispatch, user?.id]);

  function isActionAllowed(actioName: string) {
    if (user?.role === 'Admin' || actionsList?.includes(actioName)) return true;
    return false;
  }

  return (
    <Page title="مشير: قائمة | شورى">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderWithoutTabs Heading={translate('adviser')} />

        <Stack
          gap={2}
          mb={4}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          {/* //--------------------- CODE FOR FILTER-----------------------------\\ */}

          <Card sx={{ width: { xs: '100%', sm: 'auto' } }}>
            <Stack
              p={1}
              gap={2}
              direction={{ xs: 'column', sm: 'row' }}
              //spacing={{ xs: 1, sm: 3, md: 4 }}
            >
              <FormControl variant="filled" sx={{ minWidth: 120, width: 'auto' }}>
                <InputLabel id="demo-simple-select-filled-label">
                  {' '}
                  {translate('status')}{' '}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={statusFilter}
                  onChange={(e, val) => {
                    // setStatusFilter(e?.target?.value);
                    navigate(
                      `/dashboard/musher/list?/${e?.target?.value ? e?.target?.value : 0}/${0}`,
                      { replace: true }
                    );
                  }}
                >
                  {[
                    { name: 'all', id: 0 },
                    { name: 'pending', id: 1 },
                    { name: 'approved', id: 2 }
                  ].map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {' '}
                      {translate(status?.name)}{' '}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    setPage(0);
                    sendRequest();
                  }}
                  sx={{ height: '100%' }}
                >
                  {translate('filter')}
                </Button>
              </Box>
            </Stack>
          </Card>

          <Box sx={{ marginY: '1rem', width: { xs: '100%', md: '40%' } }}>
            <TextField
              sx={{
                '& fieldset': {
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#161C24 !important' : '#fff !important',
                  borderColor: 'text.primary !important'
                }
              }}
              variant="outlined"
              fullWidth
              placeholder={translate('search')}
              value={searchInput}
              onChange={(e) => setSearchInput(e?.target?.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => handleClickSerachAction()}>
                      <Icon icon={searchInput ? closeFill : searchFill} width={20} height={20} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />{' '}
          </Box>

          <Stack direction="row" flexGrow={1} sx={{ width: { xs: '100%', md: 'auto' } }}>
            {(user?.role === 'Admin' || user?.permission?.includes('questions')) && (
              <Button
                sx={{ ml: 'auto' }}
                variant="contained"
                // startIcon={<Icon icon={plusFill} width={20} height={20} />}
                onClick={() => navigate(`/dashboard/questions/list`, { replace: true })}
              >
                {translate('questions')}
              </Button>
            )}
          </Stack>
        </Stack>

        <Card sx={{ border: '1px solid', borderColor: 'background.white', mt: 4 }}>
          <Scrollbar>
            {selected.length > 0 ? (
              <MusherTableToolbar
                canUpdateStatus={isActionAllowed('UpdateStatus')}
                numSelected={selected.length}
                selectedData={selected}
                selectedStates={selectedStatus}
                AvailabilityBulkHandler={AvailabilityBulkHandler}
              />
            ) : null}
            <TableContainer sx={{ minWidth: 1000 }}>
              <Table>
                <MusherListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={mushers?.data?.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  handleRequestSort={handleRequestSort}
                  //onSelectAllClick={handleSelectAllClick}
                />
                {isLoading && <LoadingTable />}
                <TableBody>
                  {mushers?.data
                    ?.filter((data) => {
                      return data;
                    })
                    ?.map((row, index) => {
                      const {
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        userGPTStatus,
                        id,
                        createdDate
                      } = row;
                      const isItemSelected = isSelected(row.phoneNumber);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleClick(event, row.phoneNumber, row.userGPTStatus)
                          }
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          sx={
                            userGPTStatus === 'Approved'
                              ? RowDesignA
                              : userGPTStatus === 'ممنوع'
                              ? { bgcolor: 'background.mainColor' }
                              : {}
                          }
                          hover
                          key={id}
                          tabIndex={-1}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                color:
                                  userGPTStatus === 'Approved'
                                    ? theme.palette.mode === 'dark'
                                      ? 'white'
                                      : 'black'
                                    : ' '
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            {/* <Typography sx={{ color: userGPTStatus === 'Approved' ? 'black' : '' }} >
                              {firstName}
                            </Typography> */}
                            <TableLink
                              to={`${PATH_DASHBOARD.client.root}/${id}/profiles/${page}/${firstName}/${phoneNumber}`}
                            >
                              {firstName}
                            </TableLink>
                          </TableCell>

                          <TableCell align="left">
                            <Typography
                              sx={{
                                color:
                                  userGPTStatus === 'Approved'
                                    ? theme.palette.mode === 'dark'
                                      ? 'white'
                                      : 'black'
                                    : ' '
                              }}
                            >
                              {lastName}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Typography
                              sx={{
                                color:
                                  userGPTStatus === 'Approved'
                                    ? theme.palette.mode === 'dark'
                                      ? 'white'
                                      : 'black'
                                    : ' '
                              }}
                              noWrap
                            >
                              {email}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Typography
                              sx={{
                                color:
                                  userGPTStatus === 'Approved'
                                    ? theme.palette.mode === 'dark'
                                      ? 'white'
                                      : 'black'
                                    : ' '
                              }}
                              noWrap
                            >
                              {phoneNumber ? phoneNumber : '-'}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" dir="ltr">
                            <Typography
                              sx={{
                                color:
                                  userGPTStatus === 'Approved'
                                    ? theme.palette.mode === 'dark'
                                      ? 'white'
                                      : 'black'
                                    : ' '
                              }}
                              noWrap
                            >
                              {moment(createdDate).locale('en').format(`DD-MM-YYYY `)}
                              <AccessTime sx={{ mb: -0.3 }} fontSize="inherit" color="secondary" />
                              {moment(createdDate).locale('en').format(` hh:mm A`)}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            {userGPTStatus === 'Approved' ? (
                              <Typography
                                sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
                              >
                                {'موافقة'}
                              </Typography>
                            ) : (
                              <Label
                                variant="filled"
                                sx={
                                  userGPTStatus !== 'ممنوع'
                                    ? { bgcolor: 'error.lighter', color: 'secondary.main' }
                                    : {
                                        bgcolor: 'divider',
                                        color: 'action.disabled'
                                      }
                                }
                              >
                                {'قيد الانتظار'}
                              </Label>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <>
                              {changingAvailability && id === changingAvailID ? (
                                <CircularProgress size={15} />
                              ) : userGPTStatus === 'Approved' ? (
                                <Tooltip
                                  onClick={() =>
                                    isActionAllowed('UpdateStatus') && AvailabilityHandler(id)
                                  }
                                  title={null}
                                >
                                  <CheckCircleRounded sx={{ cursor: 'pointer' }} color="success" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  onClick={() =>
                                    isActionAllowed('UpdateStatus') && AvailabilityHandler(id)
                                  }
                                  title={null}
                                >
                                  <PendingRounded
                                    sx={{ color: 'text.disabled', cursor: 'pointer' }}
                                  />
                                </Tooltip>
                              )}
                            </>
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" justifyContent="center" gap={2}>
                              {isActionAllowed('UpdateStatus') && (
                                <>
                                  {
                                    changingAvailability && id === changingAvailID ? (
                                      <CircularProgress size={15} />
                                    ) : userGPTStatus !== 'Approved' ? (
                                      <Button
                                        sx={{}}
                                        startIcon={<Done />}
                                        variant="contained"
                                        color={'secondary'}
                                        onClick={() => AvailabilityHandler(id)}
                                      >
                                        قبول
                                      </Button>
                                    ) : (
                                      <></>
                                    )
                                    // <Typography sx={{ width: '70%', textAlign: 'center' }}>-</Typography>
                                  }
                                </>
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isMusherEmpty && <EmptyTable message="لا يوجد مشير فى الوقت الحالى" />}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Stack
            sx={{ padding: '1rem', bgcolor: 'background.mainColor', gap: '6px' }}
            direction={{ xs: 'column', sm: 'row-reverse' }}
            justifyContent="space-between"
          >
            <Stack direction={'row-reverse'} justifyContent="space-between" alignItems="center">
              <Pagination
                defaultPage={1}
                page={page + 1}
                onChange={(event, newPage) => {
                  if (newPage !== null)
                    // setPage(newPage - 1)
                    navigate(
                      `/dashboard/musher/list?/${statusFilter ? statusFilter : 0}/${newPage - 1}`,
                      { replace: true }
                    );
                }}
                count={Math.ceil(mushers?.totalCount / rowsPerPage) || 0}
                variant="outlined"
                shape="rounded"
              />
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(0);
                }}
                sx={{
                  height: 40,
                  width: 80,
                  '& fieldset': {
                    borderColor: 'background.tableHead'
                  }
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Stack>
            <TableDownloader disabled={true} />
          </Stack>
        </Card>
      </Container>
    </Page>
  );
}
