import { Tooltip, Typography, Button } from "@mui/material";
import { Toolbar } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import { Done } from '@mui/icons-material';

interface MusherTableToolbarProps {
    numSelected: number;
    AvailabilityBulkHandler: any;
    selectedData: ReadonlyArray<string>;
    selectedStates: ReadonlyArray<string>;
    canUpdateStatus?:boolean
}

export default function MusherTableToolbar(props: MusherTableToolbarProps) {

    let { canUpdateStatus=true } = props
    const { numSelected, AvailabilityBulkHandler, selectedData } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 2.6 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} مختارين
                </Typography>
            ) : (
                null
            )}
            {numSelected > 0 ? (
                <Tooltip title={undefined} >
                    {
                            <Button
                                sx={{
                                    display:canUpdateStatus?'':'none'
                                }}
                                startIcon={<Done />}
                                variant="contained"
                                color={'secondary'}
                                onClick={() => AvailabilityBulkHandler(selectedData)}
                            >
                                قبول
                            </Button>
                           
                    }

                </Tooltip>
            ) : (
                null
            )}
        </Toolbar>
    );
}