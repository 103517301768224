import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// utils
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import HeaderWithoutTabs from 'components/_dashboard/HeaderWithoutTabs';
import ButtonWithTabs from 'components/_dashboard/ButtonWithTabs';
import { Stack } from '@mui/material';
import { Grid } from '@mui/material';
import { getQuestionsList } from 'redux/slices/musher';
import PackageMoreMenu from 'components/_dashboard/package/list/PackageMoreMenu';
import QuestionForm from './musherManagement/QuestionForm';
import { IQuestionList } from '@types/musher';
import LoadingTable from 'components/LoadingTable';
import { Box } from '@mui/system';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import like from '@iconify/icons-ant-design/like-filled';
import disslike from '@iconify/icons-ant-design/dislike-filled';
import empty from '@iconify/icons-ant-design/minus-circle-filled';
import { Button } from '@mui/material';
import { Select } from '@mui/material';
import usePageFilter from 'hooks/usePageFilter';
import EmptyTable from 'components/EmptyTable';
import { RequestListHead } from 'components/_dashboard/request/list';
import moment from 'moment';
import { getAllowedActionsByModuleName } from 'redux/slices/permission';
import useAuth from 'hooks/useAuth';
import HeaderNavigation from 'components/_dashboard/HeaderNavigation';
// ----------------------------------------------------------------------

const TableLink = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

//-------------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'question', label: 'السؤال', alignRight: false },
  { id: 'answer', label: 'الأجابة', alignRight: false },
  { id: 'userName', label: 'اسم العميل', alignRight: false },
  { id: 'mobileNo', label: 'رقم الهاتف', alignRight: false },
  { id: 'isAccepted', label: 'التقييم', alignRight: false },
  { id: 'createdDate', label: 'تاريخ الانشاء', alignRight: false },
  { id: 'updateDate', label: 'تاريخ التحديث', alignRight: false },
  { id: '', alignRight: false }
];

// ----------------------------------------------------------------------

export default function QuestionsDetail() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPackageForm, setOpenPackageForm] = useState(false);
  const [editPackageId, setEditPackageId] = useState('');
  const [searchInput, setSearchInput] = useState<any>(null);
  const [order, setOrder] = useState<'asc' | 'desc' | ''>('');
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { questionList, isLoading } = useSelector((state: RootState) => state.musher);

  ///-----------------------------------------------------------------
  const { page, setPage, statusFilter2 } = usePageFilter();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [trackerForReload, setTrackerForReload] = useState(false);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [questions, setQuestions] = useState<IQuestionList>({
    metaData: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0
    },
    data: [],
    totalItems: 0,
    page: 0,
    size: 0
  });

  const sendRequest = () => {
    dispatch(
      getQuestionsList({
        page: page ? page : 1,
        size: rowsPerPage,
        questionId: searchInput ? searchInput : null,
        isAccepted: statusFilter2 === 0 ? null : statusFilter2,
        orderBy: orderBy,
        sortBy: order
      })
    );
  };

  useEffect(() => {
    if (questionList) {
      setQuestions({
        ...questionList,
        data: questionList?.data
      });
    }
  }, [questionList]);

  useEffect(() => {
    if (searchInput) {
      dispatch(
        getQuestionsList({
          page: 0,
          size: rowsPerPage,
          isAccepted: null,
          questionId: searchInput ? searchInput : null,
          orderBy: orderBy,
          sortBy: order
        })
      );
    } else
      dispatch(
        getQuestionsList({
          page: page ? page + 1 : 0,
          size: rowsPerPage,
          isAccepted: statusFilter2 === 0 ? null : statusFilter2,
          questionId: searchInput ? searchInput : null,
          orderBy: orderBy,
          sortBy: order
        })
      );
  }, [dispatch, searchInput, page, rowsPerPage, statusFilter2, trackerForReload, orderBy, order]);

  const handleRequestSort = (isAsc: any, property: any) => {
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClickSerachAction = () => {
    if (searchInput) {
      setSearchInput('');
    } else {
      return;
    }
  };

  const navigateBack = () => {
    navigate(PATH_DASHBOARD.musher.list);
  };

  const isRequestEmpty = !isLoading && questionList && questionList?.data?.length === 0;

  const { user } = useAuth();
  const { actionsList } = useSelector((state: RootState) => state.permission);

  useEffect(() => {
    dispatch(getAllowedActionsByModuleName(user?.id, 'questions'));
  }, [dispatch, user?.id]);

  function isActionAllowed(actioName: string) {
    if (user?.role === 'Admin' || actionsList?.includes(actioName)) return true;
    return false;
  }

  return (
    <Page title="العملاء: الاسئلة | شورى">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderNavigation Heading="الاسئلة"  navigateBack={navigateBack} />{' '}
        <Stack
          gap={2}
          mb={4}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <Card sx={{ width: { xs: '100%', sm: 'auto' } }}>
            <Stack p={1} gap={2} direction={{ xs: 'column', sm: 'row' }}>
              <FormControl variant="filled" sx={{ minWidth: 120, width: 'auto' }}>
                <InputLabel id="demo-simple-select-filled-label"> التقييم </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={statusFilter2}
                  onChange={(e, val) => {
                    navigate(`/dashboard/questions/list?/${e?.target?.value}/${0}`, {
                      replace: true
                    });
                  }}
                >
                  {[
                    { name: 'الكل', id: 0 },
                    { name: 'اعجاب', id: true },
                    { name: 'رفض', id: false }
                  ].map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {' '}
                      {status?.name}{' '}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    setPage(0);
                    sendRequest();
                  }}
                  sx={{ height: '100%' }}
                >
                  فلترة
                </Button>
              </Box>
            </Stack>
          </Card>

          <Box sx={{ marginY: '1rem', width: { xs: '100%', md: '40%' } }}>
            <TextField
              sx={{
                '& fieldset': {
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#161C24 !important' : '#fff !important',
                  borderColor: 'text.primary !important'
                }
              }}
              variant="outlined"
              fullWidth
              placeholder="بحث"
              value={searchInput}
              onChange={(e) => setSearchInput(e?.target?.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => handleClickSerachAction()}>
                      <Icon icon={searchInput ? closeFill : searchFill} width={20} height={20} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />{' '}
          </Box>

          <Stack direction="row" flexGrow={1} sx={{ width: { xs: '100%', md: 'auto' } }}></Stack>
        </Stack>
        <Card sx={{ width: { xs: '100%', sm: 'auto' } }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <RequestListHead
                  headLabel={TABLE_HEAD}
                  handleRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  hasSort={true}
                />
                {isLoading && !openPackageForm && <LoadingTable />}
                <TableBody>
                  {questions?.data.map((row, index) => {
                    const {
                      question,
                      answer,
                      rating,
                      id,
                      isAccepted,
                      userName,
                      mobileNo,
                      createdDate,
                      updateDate,
                      userId
                    } = row;

                    return (
                      <TableRow hover tabIndex={-1} key={id}>
                        <TableCell align="left">
                          <Typography
                            // noWrap
                            color="warning"
                          >
                            {question}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography color="warning">{answer}</Typography>
                        </TableCell>

                        <TableCell align="left">
                          <TableLink
                            to={`${PATH_DASHBOARD.client.root}/${userId}/profiles/${page}/${userName}/${mobileNo}`}
                            color="warning"
                          >
                            {userName}
                          </TableLink>
                        </TableCell>

                        <TableCell align="left">
                          <Typography color="warning">{mobileNo}</Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={
                              isAccepted !== null ? (isAccepted ? 'success' : 'error') : 'default'
                            }
                          >
                            {
                              <Icon
                                icon={isAccepted !== null ? (isAccepted ? like : disslike) : empty}
                                width={15}
                                height={15}
                              />
                            }
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {moment(createdDate).locale('en').format(`DD-MM-YYYY `)}
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {moment(updateDate).locale('en').format(`DD-MM-YYYY `)}
                        </TableCell>

                        <TableCell align="right">
                          {id && (
                            <PackageMoreMenu
                              canDelete={isActionAllowed('Delete')}
                              canEdit={isActionAllowed('Update')}
                              title="تعديل"
                              onDelete={() => {
                                setOpenDeleteModal(true);
                              }}
                              packageId={id}
                              setEditPackageId={setEditPackageId}
                              setOpenPackageForm={setOpenPackageForm}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isRequestEmpty && <EmptyTable message="لا يوجد اسئلة فى الوقت الحالى" />}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={Math.ceil(questions?.metaData?.totalItems / rowsPerPage) || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => {
              if (newPage !== null)
                navigate(`/dashboard/questions/list?/${statusFilter2}/${newPage}`, {
                  replace: true
                });
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '80%', md: '60%' }
            }
          }
        }}
        open={openPackageForm}
      >
        <DialogContent sx={{ paddingTop: 0 }}>
          <QuestionForm setDisplay={setOpenPackageForm} editPackageId={editPackageId} />
        </DialogContent>
      </Dialog>
    </Page>
  );
}
