import { useEffect } from 'react';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import Page from '../../../components/Page';
import B2bCompanyForm from '../../../components/_dashboard/b2b/B2bCompanyForm';
import { getB2bCompany } from 'redux/slices/b2b';
import { B2bCompanies, B2bCompanyCustomersUpdate } from '../../../@types/b2b';
import B2bCompanyCustomerForm from 'components/_dashboard/b2b/B2bCompanyCustomerForm';

type B2bCompanyCustomersCreateProps = {
  name: string,
  setDisplay: Function,
  b2b: B2bCompanyCustomersUpdate;
  companyId:string

}
export default function B2bCompanyCustomersCreate({ name, setDisplay, b2b ,companyId }: B2bCompanyCustomersCreateProps) {
  const dispatch = useDispatch();
  const isEdit = name === '' ? false : true;

  const { b2bCompany, isLoading, } = useSelector(
    (state: RootState) => state.b2b
  );


  // useEffect(() => {

  //   if (isEdit)
  //     dispatch(getB2bCompany(name));
  // }, [dispatch, name, isEdit]);

  return (
    <Page>
      <B2bCompanyCustomerForm isEdit={isEdit} b2bCompany={b2b} setDisplay={setDisplay} companyId={companyId} />
    </Page>

  );
}
