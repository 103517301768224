import * as Yup from 'yup';
import { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Box,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Track button enabled state
  const [isLoaded, setIsLoaded] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('برجاء إدخال بريد إالكتروني صحيح')
      .required('برجاءإدخال البريد الإلكتروني'),
    password: Yup.string().required('برجاء إدخال الرقم السري')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: false
    },
    validationSchema: LoginSchema,
    validateOnMount: true, // Validate on form mount
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);

        if (values.remember) {
          // Save credentials to localStorage
          localStorage.setItem('email', values.email);
          localStorage.setItem('password', values.password);
          localStorage.setItem('remember', String(values.remember));
        } else {
          // Clear localStorage if remember is unchecked
          localStorage.removeItem('email');
          localStorage.removeItem('password');
          localStorage.removeItem('remember');
        }

        enqueueSnackbar('تم تسجيل الدخول بنجاح', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        Object.values(error.errors)
          .flat()
          .map((err: any) => {
            return enqueueSnackbar(err, { variant: 'error' });
          });

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const { translate } = useLocales();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      '& fieldset': {
        background:
          formik.isValid &&
          (theme.palette.mode === 'dark'
            ? `${theme.palette.error.darker} !important`
            : `${theme.palette.error.lighter} !important`)
      }
    }
  });
  const classes = useStyles();
  const checkCred = useCallback(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    const savedRemember = localStorage.getItem('remember') === 'true';

    if (!isLoaded && savedEmail && savedPassword) {
      formik.setValues({
        email: savedEmail,
        password: savedPassword,
        remember: savedRemember
      });

      setIsButtonEnabled(true); 
      setIsLoaded(true); 
    }
  }, [formik, isLoaded]);

  useEffect(() => {
    checkCred(); 
  }, [checkCred]);

  useEffect(() => {
    setIsButtonEnabled(!!(formik.values.email && formik.values.password));
  }, [formik.values.email, formik.values.password]);

  useEffect(() => {
    if (formik.values.email && formik.values.password) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [formik.values.email, formik.values.password]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          <Box>
            <Typography sx={{ opacity: 0.7, mb: 1 }}>البريد الاليكترونى</Typography>
            <TextField
              className={classes.root}
              fullWidth
              autoComplete="username"
              type="email"
              placeholder="lawyer@shwra.sa"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Box>

          <Box>
            <Typography sx={{ opacity: 0.7, mb: 1 }}>{translate('password')}</Typography>
            <TextField
              className={classes.root}
              fullWidth
              autoComplete="current-password"
              placeholder="password"
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Box>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="تذكرنى المرة القادمة"
          />

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgetPassword}>
            {'نسيت كلمة المرور ؟'}
          </Link>
        </Stack>

        <LoadingButton
          disabled={!isButtonEnabled} // Enable/disable based on form values
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          تسجيل الدخول
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
