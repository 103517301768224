import moment from 'moment';
import { Box } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { styled, useTheme } from '@mui/material/styles';

import Scrollbar from '../../Scrollbar';
import DataCard from '../../shared/dataCard/DataCard';
import { useTotalAppointments } from '../../../queries/dashboard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getAppointmentsByMonthDetail } from 'redux/slices/report';
import LoadingTable from 'components/LoadingTable';
import DataCardCalendar from 'components/shared/dataCard/DataCardCalendar';
import useLocales from 'hooks/useLocales';

const ToolTip = styled('div')(() => ({
  backgroundColor: '#fff',
  padding: '5px 10px',
  border: '1px solid #DFE3E8',
  fontSize: '12px',
  borderRadius: '5px',
  direction: 'rtl'
}));


export default function TotalAppoinments() {
  const { data } = useTotalAppointments();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { AppointmentsByMonthDetail, isLoading, AppointmentsByMonthDetailApp } = useSelector(
    (state: RootState) => state.report
    );
    const totalAppointments = AppointmentsByMonthDetail?.total;

  useEffect(() => {
    dispatch(getAppointmentsByMonthDetail());
  }, [dispatch]);


  return (
    isLoading && AppointmentsByMonthDetail?.appointments?.length === 0 ? (
      <LoadingTable />
    ) : (
      <DataCardCalendar 
      title={translate('total appointments')} statistics={totalAppointments}>
        <Box
          sx={{
            direction: theme.direction
          }}
        >
          <Scrollbar
            sx={{
              width: '100%',
              overflowY: 'hidden',
              overflowX: 'auto'
            }}
            // @ts-ignore
            scrollStyles={{
              bar: {
                backgroundColor: '#d49e24'
              },
              track: {
                backgroundColor: '#F4F6F8'
              }
            }}
          >
            <Box sx={{ height: 210, minWidth: '500px' }}>
              {/*<Bar options={options} data={chartData} />*/}
              <ResponsiveBar
                tooltip={(e) => <ToolTip>{`${translate('appointments')} ${e.data.total} `}</ToolTip>}
                data={AppointmentsByMonthDetailApp}
                minValue={0}
                keys={['total']}
                indexBy="index"
                margin={{ top: 50, right: 10, bottom: 40, left: 30 }}
                padding={0.25}
                defs={[
                  {
                    id: 'gradientC',
                    type: 'linearGradient',
                    colors: [
                      { offset: 0, color: '#d49e24' },
                      { offset: 100, color: '#dab65e' }
                    ]
                  }
                ]}
                fill={[
                  {
                    match: {
                      id: 'total'
                    },
                    id: 'gradientC'
                  }
                ]}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                gridYValues={5}
                axisLeft={{
                  tickValues: 5,
                  tickSize: 0,
                  renderTick: ({ value, x, y }) => {
                    return (
                      <g transform={`translate(${x - 25},${y + 4})`}>
                        <text
                          style={{
                            fill: theme.palette.mode === 'dark'   ? 'white' : '#161C24',
                            fontSize: '12px',
                            marginRight: '10px'
                          }}
                        >
                          {value}
                        </text>
                      </g>
                    );
                  },
                  tickPadding: 40,
                  tickRotation: 0,
                  legend: ''
                }}
                enableLabel={true}
                labelSkipHeight={4}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]]
                }}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fill: theme.palette.mode === 'dark'   ? 'white' : '#161C24',
                        // Change this to your desired color
                      },
                    },
                  },
                }}
              />
            </Box>
          </Scrollbar>
        </Box>
      </DataCardCalendar>
    )
  );
}
