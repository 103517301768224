import * as Sentry from "@sentry/react";
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

const tabSlice = createSlice({
    name:'tab',
    initialState : {
      slectedTab:1
    },
    reducers: {
      changeTabSuccess(state,action)
      {
        state.slectedTab = action.payload
      }
    }
  })

  export default tabSlice.reducer;

  
  export  function changeTab(tab:number)
  { 
    return async () => {
         dispatch(tabSlice.actions.changeTabSuccess(tab))
    }
  }