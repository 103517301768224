import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Stack } from '@mui/material';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import { getConfigurationsList } from '../../../redux/slices/user';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import CreateEditConfiguration from 'components/_dashboard/configuration/CreateEditConfiguration';
import { Card } from '@mui/material';
import { Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function ConfigurationForm() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { configurationId = '' } = useParams();
  const isEdit = true;
  const { configurationsList } = useSelector((state: RootState) => state.user);
  const [tableFilters, setTableFilters] = useState(1)

  useEffect(() => {
    dispatch(getConfigurationsList(tableFilters));
  }, [dispatch,tableFilters]);



  const FilterHolders: {} = {
    p: 1,
    bgcolor: 'background.paper',
    display: { xs: 'flex', sm: 'inline-flex' },
    gap: 1,
    flexWrap: 'wrap',
    borderRadius: { xs: 4, sm: 12 },
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'center',
  }

  const filterHandler = async (filter: any) => {
    setTableFilters(filter)
    // setPage(0)
  }

  const ActiveButtonDesign = {
    bgcolor: 'error.lighter',
    color: 'black',
    boxShadow: 'none',
    borderRadius: 12,
    '&.MuiButton-root:hover': {
      bgcolor: 'error.lighter',
    },

  }
  const InActiveButtonDesign = {
    bgcolor: 'background.paper',
    color: 'text.primary',
    boxShadow: 'none',
    borderRadius: 12,
    '&.MuiButton-root:hover': {
      bgcolor: 'background.paper',
    },

  }

  return (
    <Page title="العملاء: انشاء إعدادات جديد | شورى">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'انشاء إعدادات جديد' : 'تعديل الإعدادات'}
          links={[
            { name: 'الرئيسية', href: PATH_DASHBOARD.root },
            { name: 'الإعدادات', href: PATH_DASHBOARD.user.account },
            { name: !isEdit ? 'إعدادات جديد' : 'تعديل' }
          ]}
        />
        <Stack justifyContent='center' sx={{ mb: 5 }} alignItems={{ xs: '', sm: 'center' }} ml={{ xs: '0', sm: '2rem' }} >
          <Card sx={FilterHolders} >

            <Button color='success'  onClick={() => filterHandler(1)} sx={tableFilters === 1 ? ActiveButtonDesign : InActiveButtonDesign} >
              تطبيق العملاء
            </Button>

            <Button  onClick={() => filterHandler(2)} sx={tableFilters === 2 ? ActiveButtonDesign : InActiveButtonDesign} color='success' >
              تطبيق المحامين
            </Button>

            <Button color='success'  onClick={() => filterHandler(3)} sx={tableFilters === 3 ? ActiveButtonDesign : InActiveButtonDesign} >
              الموقع
            </Button>

            <Button color='success'  onClick={() => filterHandler(4)} sx={tableFilters === 4 ? ActiveButtonDesign : InActiveButtonDesign} >
              عام

            </Button>


          </Card>


        </Stack>
        <CreateEditConfiguration
          isEdit={isEdit}
          configurationId={configurationId}
          configurationsList={configurationsList}
        />
      </Container>
    </Page>
  );
}
