import { ConsultationForm } from 'consultation-form'
import React from 'react'

function Consultation() {
  return (
    <div>
        <ConsultationForm/>
    </div>
  )
}

export default Consultation