import { Interval } from '../@types/availability';


export const MOCK_RULS = [
    {
       dayId: "monday",
       intervals: [
            {
               from: "08:00",
               to: "08:30"
            },
            {
               from: "08:30",
               to: "09:00"
            }
       ]
    },
    {
       dayId: "tuesday",
       intervals: [
          {
             from: "08:00",
             to: "12:00"
          },
          {
             from: "13:00",
             to: "17:00"
          }
       ]
    },
    {
       dayId: "wednesday",
       intervals: [
          {
             from: "08:00",
             to: "12:00"
          },
          {
             from: "13:00",
             to: "17:00"
          }
       ]
    },
    {
       dayId: "thursday",
       intervals: [
          {
             from: "08:00",
             to: "12:00"
          },
          {
             from: "13:00",
             to: "17:00"
          }
       ]
    },
    {
       dayId: "friday",
       intervals: [
          {
             from: "08:00",
             to: "12:00"
          },
          {
             from: "13:00",
             to: "17:00"
          }
       ]
    },
    {
       dayId: "saturday",
       intervals: [
          {
             from: "08:00",
             to: "12:00"
          },
          {
             from: "13:00",
             to: "17:00"
          }
       ]
    },
    {
       dayId: "sunday",
       intervals: [
          {
             from: "08:00",
             to: "12:00"
          },
          {
             from: "13:00",
             to: "17:00"
          }
       ]
    }
 ]



export const TIMES: string[] = [
   '00:00',
   '00:30',
   '01:00',
   '01:30',
   '02:00',
   '02:30',
   '03:00',
   '03:30',
   '04:00',
   '04:30',
   '05:00',
   '05:30',
   '06:00',
   '06:30',
   '07:00',
   '07:30',
   '08:00',
   '08:30',
   '09:00',
   '09:30',
   '10:00',
   '10:30',
   '11:00',
   '11:30',
   '12:00',
   '12:30',
   '13:00',
   '13:30',
   '14:00',
   '14:30',
   '15:00',
   '15:30',
   '16:00',
   '16:30',
   '17:00',
   '17:30',
   '18:00',
   '18:30',
   '19:00',
   '19:30',
   '20:00',
   '20:30',
   '21:00',
   '21:30',
   '22:00',
   '22:30',
   '23:00',
   '23:30',
   '24:00'
 ];
 

 export const FULL_INTERVAL: Interval[] = [
      {
         from: '00:00',
         to: '00:30'
      },
      {
         from: '00:30',
         to: '01:00'
      },
      {
         from: '01:00',
         to: '01:30'
      },
      {
         from: '01:30',
         to: '02:00'
      },
      {
         from: '02:00',
         to: '02:30'
      },
      {
         from: '02:30',
         to: '03:00'
      },
      {
         from: '03:00',
         to: '03:30'
      },
      {
         from: '03:30',
         to: '04:00'
      },
      {
         from: '04:00',
         to: '04:30'
      },
      {
         from: '04:30',
         to: '05:00'
      },
      {
         from: '05:00',
         to: '05:30'
      },
      {
         from: '05:30',
         to: '06:00'
      },
      {
         from: '06:00',
         to: '06:30'
      },
      {
         from: '06:30',
         to: '07:00'
      },
      {
         from: '07:00',
         to: '07:30'
      },
      {
         from: '07:30',
         to: '08:00'
      },
      {
         from: '08:00',
         to: '08:30'
      },
      {
         from: '08:30',
         to: '09:00'
      },
      {
         from: '09:00',
         to: '09:30'
      },
      {
         from: "09:30",
         to: "10:00"
      },
      {
         from: "10:00",
         to: "10:30"
      },
      {
         from: "10:30",
         to: "11:00"
      },
      {
         from: "11:00",
         to: "11:30"
      },
      {
         from: "11:30",
         to: "12:00"
      },
      {
         from: "12:00",
         to: "12:30"
      },
      {
         from: "12:30",
         to: "13:00"
      },
      {
         from: "13:00",
         to: "13:30"
      },
      {
         from: "13:30",
         to: "14:00"
      },
      {
         from: "14:00",
         to: "15:00"
      },
      {
         from: "15:00",
         to: "15:30"
      },
      {
         from: "15:30",
         to: "16:00"
      },
      {
         from: "16:00",
         to: "16:30"
      },
      {
         from: "16:30",
         to: "17:00"
      },
      {
         from: "17:00",
         to: "17:30"
      },
      {
         from: "17:30",
         to: "18:00"
      },
      {
         from: "18:00",
         to: "18:30"
      },
      {
         from: "18:30",
         to: "19:00"
      },
      {
         from: "19:00",
         to: "19:30"
      },
      {
         from: "19:30",
         to: "20:00"
      },
      {
         from: "20:00",
         to: "20:30"
      },
      {
         from: "20:30",
         to: "21:00"
      },
      {
         from: "21:00",
         to: "21:30"
      },
      {
         from: "21:30",
         to: "22:00"
      },
      {
         from: "22:00",
         to: "22:30"
      },
      {
         from: "22:30",
         to: "23:00"
      },
      {
         from: "23:00",
         to: "23:30"
      },
      {
         from: "23:30",
         to: "24:00"
      },
      //{
      //   from: "24:00",
      //   to: "24:00"  
      //}
   ];



   export const USER_FREE_INTERVAL: Interval[] = [
      {
         from: '00:00',
         to: '00:30'
      },
      {
         from: '00:30',
         to: '01:00'
      },
      {
         from: '01:00',
         to: '01:30'
      },
      {
         from: '01:30',
         to: '02:00'
      },
      {
         from: '02:00',
         to: '02:30'
      },
      {
         from: '02:30',
         to: '03:00'
      },
      {
         from: '03:00',
         to: '03:30'
      },
      {
         from: '03:30',
         to: '04:00'
      },
      {
         from: '04:00',
         to: '04:30'
      },
      {
         from: '04:30',
         to: '05:00'
      },
      {
         from: '05:00',
         to: '05:30'
      },
      {
         from: '05:30',
         to: '06:00'
      },
      {
         from: '06:00',
         to: '06:30'
      },
      {
         from: '06:30',
         to: '07:00'
      },
      {
         from: '07:00',
         to: '07:30'
      },
      {
         from: '07:30',
         to: '08:00'
      },
      {
         from: '09:00',
         to: '09:30'
      },
      {
         from: "09:30",
         to: "10:00"
      },
      {
         from: "10:00",
         to: "10:30"
      },
      {
         from: "10:30",
         to: "11:00"
      },
      {
         from: "11:00",
         to: "11:30"
      },
      {
         from: "11:30",
         to: "12:00"
      },
      {
         from: "12:00",
         to: "12:30"
      },
      {
         from: "12:30",
         to: "13:00"
      },
      {
         from: "13:00",
         to: "13:30"
      },
      {
         from: "13:30",
         to: "14:00"
      },
      {
         from: "14:00",
         to: "15:00"
      },
      {
         from: "15:00",
         to: "15:30"
      },
      {
         from: "15:30",
         to: "16:00"
      },
      {
         from: "16:00",
         to: "16:30"
      },
      {
         from: "16:30",
         to: "17:00"
      },
      {
         from: "17:00",
         to: "17:30"
      },
      {
         from: "17:30",
         to: "18:00"
      },
      {
         from: "18:00",
         to: "18:30"
      },
      {
         from: "18:30",
         to: "19:00"
      },
      {
         from: "19:00",
         to: "19:30"
      },
      {
         from: "19:30",
         to: "20:00"
      },
      {
         from: "20:00",
         to: "20:30"
      },
      {
         from: "20:30",
         to: "21:00"
      },
      {
         from: "21:00",
         to: "21:30"
      },
      {
         from: "21:30",
         to: "22:00"
      },
      {
         from: "22:00",
         to: "22:30"
      },
      {
         from: "22:30",
         to: "23:00"
      },
      {
         from: "23:00",
         to: "23:30"
      },
      {
         from: "23:30",
         to: "24:00"
      },
   ]
