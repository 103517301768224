import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { createFileUrl } from 'components/file/FilePreview';
import { scheduleDemoState } from '../../@types/scheduleDemo';

// ----------------------------------------------------------------------

const initialState: scheduleDemoState = {
  isLoading: false,
  error: false,
  scheduleDemoList: [],
  ScheduleDemoDetails: [],
  SchduleDemoStatusChange: [],
  showStatus: [],
  isStatusResponse: null,
  DateOfMeeting: [],
  EmployeeList: [],
  MarkAvailbleEmployee: [],
  ReScheduleDemo: [],
  IsDemoUpdated: null
};

const slice = createSlice({
  name: 'scheduleDemo',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getScheduleDemoListSuccess(state, action) {
      state.isLoading = false;
      state.scheduleDemoList = action.payload;
    },
    getScheduleDemoDetails(state, actions) {
      state.ScheduleDemoDetails = actions.payload;
    },
    setSchduleDemoStatusChange(state, action) {
      state.SchduleDemoStatusChange = action.payload;
    },
    setShowStatus(state, action) {
      state.showStatus = action.payload;
    },
    setIsStatusResponse(state, action) {
      state.isStatusResponse = action.payload;
    },
    setIsDateOfMeeting(state, action) {
      state.DateOfMeeting = action.payload;
    },
    setEmployeeList(state, action) {
      state.EmployeeList = action.payload;
    },
    setMarkAvailbleEmployee(state, action) {
      state.MarkAvailbleEmployee = action.payload;
    },
    setReScheduleDemo(state, action) {
      state.ReScheduleDemo = action.payload;
    },
    setIsDemoUpdated(state, action) {
      state.IsDemoUpdated = action.payload;
    }
  }
});

export default slice.reducer;

//-------------------------------------------------------------------------------------

export function getScheduledDemoList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/B2b/GetScheduledDemo');
      let result = response?.data?.result;

      dispatch(slice.actions.getScheduleDemoListSuccess(result));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error, 'error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getScheduledDemoDetailsDate(date: string, time: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/AppAdmin/GetEmployeesByTimeAndDate?meetingDate=${date}&MeetingTime=${time}`
      );
      let result = response?.data?.result;
      dispatch(slice.actions.setIsDateOfMeeting(result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function GetEmployeeListForDemo() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/AppAdmin/GetAllEmployees');
      dispatch(slice.actions.setEmployeeList(response.data)); // Assuming response.data contains the employee array
    } catch (error) {
      console.error('Error fetching employees:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function MarkEmployeeAvailbeforDemo({
  EmployeeId,
  MeetingDate,
  MeetingTime,
  IsAvailable
}: {
  EmployeeId: string;
  MeetingDate: string;
  MeetingTime: string;
  IsAvailable: boolean;
}) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Slot/MarkEmployeeSlotAvailable', {
        EmployeeId,
        MeetingDate,
        MeetingTime,
        IsAvailable
      });
      dispatch(slice.actions.setMarkAvailbleEmployee(response.data)); // Assuming response.data contains the employee array
    } catch (error) {
      console.error('Error fetching employees:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function UpdatedemoReSchedule(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/AppAdmin/ReScheduleDemo', data);
      if (response?.data?.status === false) {
        dispatch(slice.actions.setIsDemoUpdated(false));
        dispatch(slice.actions.setReScheduleDemo(response?.data?.errors[0]));
      } else {
        dispatch(slice.actions.setIsDemoUpdated(true));
        dispatch(slice.actions.setReScheduleDemo(response?.data?.result));
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getScheduledStatusChange(userId: string, isDemoMode: boolean) {
//   return async (dispatch: any) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/AppAdmin/SwitchB2BCustomerDemoMode', {
//         userId,
//         isDemoMode
//       });

//       if (response?.data?.status === false) {
//         dispatch(slice.actions.setIsStatusResponse(false)); // Set status response for error
//         dispatch(slice.actions.setShowStatus(response?.data?.errors));
//       } else {
//         const result = response?.data?.result;
//         dispatch(slice.actions.setSchduleDemoStatusChange(result));
//         dispatch(slice.actions.setIsStatusResponse(true)); // Set status response for success
//       }
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.setIsStatusResponse(false)); // Set status response for error
//     }
//   };
// }

export function getScheduledStatusChange(userId: string, isDemoMode: boolean) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/AppAdmin/SwitchB2BCustomerDemoMode', {
        userId,
        isDemoMode
      });

      if (response?.data?.status === false) {
        // Ensure that only error status is set
        dispatch(slice.actions.setIsStatusResponse(false)); // Set status response for error
        dispatch(slice.actions.setShowStatus(response?.data?.errors)); // Set error message
      } else {
        const result = response?.data?.result;
        // Only set success if there's no error
        dispatch(slice.actions.setSchduleDemoStatusChange(result));
        dispatch(slice.actions.setIsStatusResponse(true)); // Set status response for success
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setIsStatusResponse(false)); // Set status response for error
      dispatch(slice.actions.setShowStatus(['An error occurred. Please try again.'])); // Set default error message
    }
  };
}

export function getScheduledDemoDetails(email: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/AppAdmin/GetScheduledDemoByEmail?email=${email}`);
      let result = response?.data?.result;

      dispatch(slice.actions.getScheduleDemoDetails(result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function initiateScheduleDemoCall(employeePhoneNumber: number, customerPhoneNumber: number) {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());

      const response = await axios.post(
        `B2b/InitiateCallBetweenCustomerAndEmployee?employeePhoneNumber=${employeePhoneNumber}&customerPhoneNumber=${customerPhoneNumber}`
      );
      dispatch(slice.actions.stopLoading());
      return response?.data;
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setScheduledDemoList(data: any) {
  return dispatch(slice.actions.getScheduleDemoListSuccess(data));
}

// export function getPackageDetails(packageId: string | number) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/Package/${packageId}`);

//       dispatch(slice.actions.getPackageSuccess({ ...response?.data?.result, image: '' }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function setPackageDetails(data: any) {
//   return dispatch(slice.actions.getPackageSuccess(data));
// }

// export function addPackage(data: Package) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(`/Package/Create`, data);
//       if(response?.data?.status === false)
//       {

//         dispatch(slice.actions.addPackageStatus({ status: 'error', error: response?.data }));
//       }
//       else
//       dispatch(slice.actions.addPackageStatus({ status: 'success', ...response?.data?.result }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.addPackageStatus({ status: 'error', error: error }));
//     }
//   };
// }

// export function setAddPackage(data: any) {
//   return dispatch(slice.actions.addPackageStatus(data));
// }

// export function editpackage(data: Package) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.put(`/Package/Update`, data);
//       if(response?.data?.status === false)
//       {

//         dispatch(slice.actions.editPackageStatus({ status: 'error', error: response?.data }));
//       }
//       else
//       dispatch(slice.actions.editPackageStatus({ status: 'success', ...response?.data?.result }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.editPackageStatus({ status: 'error', error: error }));
//     }
//   };
// }

// export function setEditPackage(data: any) {
//   return dispatch(slice.actions.editPackageStatus(data));
// }

// export function deletePackage(packageId: any, lastModifiedBy: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.delete(`/Package/Delete`,
//       {
//         data:{
//         "id":packageId,
//         "isActive":false,
//         "lastModifiedBy":lastModifiedBy
//       }
//      });
//       dispatch(slice.actions.deletePackageStatus({ status: 'success', ...response?.data?.result }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.deletePackageStatus({ status: 'error', error: error }));
//     }
//   };
// }

// export function setDeletePackage(data: any) {
//   return dispatch(slice.actions.deletePackageStatus(data));
// }
export function setIsStatusReset(data: any) {
  return dispatch(slice.actions.setIsStatusResponse(data));
}
export function SetIsStatusEmpty(data: any) {
  return dispatch(slice.actions.setShowStatus(data));
}
export function ResetIsUpdatedStatus(data: any) {
  return dispatch(slice.actions.setIsDemoUpdated(data));
}
export function SetReScheduleDemoEmpty(data: any) {
  return dispatch(slice.actions.setReScheduleDemo(data));
}
