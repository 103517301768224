import { useParams, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import FormHeader from 'components/_dashboard/FormHeader';
import CreateEditQuestions from 'components/_dashboard/musher/CreateEditQuestions';

type packageFormProps = {
  setDisplay:Function;
  editPackageId:string;
}
export default function QuestionForm( { setDisplay,editPackageId } : packageFormProps ) {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const isEdit = editPackageId ? true : false


  return (
    <Page title="العملاء: الاسئلة  | شورى">
        <CreateEditQuestions setDisplay={setDisplay} isEdit={isEdit} packageId={editPackageId} />
    </Page>
  );
}
