import {  useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';


export default function usePageFilter(){
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [page, setPage] = useState(Number(search.substring(4)));
  const [statusFilter, setStatusFilter] = useState<string | number>(Number(search.substring(2,3)));
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [statusFilter2, setStatusFilter2] = useState<string | number | null | boolean>(0);

  const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setStatusFilter(0);
    setStatusFilter2(0);
  };
  
  useEffect(() => {
    setPage(typeof(statusFilter2) !== "number" ?  Number(search?.split("/")[2]?.trim()) :  Number(search.substring(4)))
    setStatusFilter(Number(search?.substring(2,3)))
    setStatusFilter2(search?.split("/")[1]?.trim() === "true" ? true :search?.split("/")[1]?.trim() === "false" ? false :0)

  }, [search,statusFilter2]);
  
  return {
    page,
    statusFilter,
    statusFilter2,
    order,
    selected,
    rowsPerPage,
    changeRowsPerPage,
    setPage,
    setStatusFilter,
    setStatusFilter2
  }
}