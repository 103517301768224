// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import arLocale from 'date-fns/locale/ar-SA';
import enLocale from 'date-fns/locale/en-US';


// import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import { useEffect, useRef } from 'react';
import { useDetectInActivity } from 'hooks/useDetectInActivity';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------
//-------Code For session Logout-------//


export default function App() {
  const { isInitialized } = useAuth();
  const { currentLang } = useLocales();

   useDetectInActivity(); 

  //---------

  return (
    <ThemeConfig> 
      <LocalizationProvider dateAdapter={DateAdapter} locale={ currentLang.value === 'en' ? enLocale : arLocale} >
        <ThemePrimaryColor>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                {/* <BaseOptionChartStyle /> */}
                <Settings />
                <ScrollToTop />
                <GoogleAnalytics />
                {isInitialized ? <Router /> : <LoadingScreen />}
              </NotistackProvider>
            </RtlLayout>  
          </ThemeLocalization>
        </ThemePrimaryColor>
      </LocalizationProvider>
    </ThemeConfig>
  );
}
