import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import { useTranslation } from 'react-i18next'; // Assuming you're using react-i18next
import { el } from 'date-fns/locale';

const HeaderNavigation = ({
  Heading,
  Link = PATH_DASHBOARD.root,
  navigateBack
}: {
  Heading: string;
  Link?: any;
  navigateBack?: any;
}) => {
  const nav = useNavigate();
  const { i18n } = useTranslation(); // Assuming you're using react-i18next

  const isArabic = i18n.language === 'ar'; // Check if the current language is Arabic

  function navBack() {
    navigateBack();
  }
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} mb={4} justifyContent="space-between">
      <Typography variant="h4" gutterBottom>
        {Heading}
      </Typography>

      {isArabic ? (
        <KeyboardArrowLeft
          onClick={navBack}
          sx={{ alignSelf: { xs: 'end', sm: 'center' }, cursor: 'pointer' }}
        />
      ) : (
        <KeyboardArrowRight
          onClick={navBack}
          sx={{ alignSelf: { xs: 'end', sm: 'center' }, cursor: 'pointer' }}
        />
      )}
    </Stack>
  );
};

export default HeaderNavigation;
