import { Restore } from '@mui/icons-material';
import { Box, FormControl, FormHelperText, IconButton, InputLabel, TextField } from '@mui/material';
import { Card, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SubTitle from 'components/SubTitles';
import { useFormik } from 'formik';
import useLocales from 'hooks/useLocales';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { getAppointmentsByMonthDetail } from 'redux/slices/report';

type DataCardCalendarProps = {
  children?: ReactNode;
  title?: string;
  statisticsText?: string;
  statistics?: number | string | ReactNode;
  sx?: CSSProperties;
};

const NumberText = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: '#d49e24',
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  fontFamily: 'bold'
}));
const CardTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #DFE3E8'
}));
export default function DataCardCalendar({
  children,
  statistics,
  statisticsText,
  title,
  sx = {}
}: DataCardCalendarProps) {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const theme = useTheme();
  const [isSubmitting, setSubmitting] = useState(false);
  const today = new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":"));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        validFrom: '',
        validTo: '',
    },
    validateOnMount: true,
    onSubmit: async (values, { resetForm, setErrors }) => {
        try {
            let bodyFormData = new FormData();
            bodyFormData.append('validFrom', values.validFrom);
            bodyFormData.append('validTo', values.validTo);
            setSubmitting(true);
        } catch (error: any) {
            setErrors(error);
        }
    }
});

const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;


const resetAppointmentCalender = ()=>{

  if(values.validTo && values.validFrom)
    {
    formik.resetForm();
  dispatch(getAppointmentsByMonthDetail("",""));
  }
}
useEffect(() => {
  if (values.validFrom && values.validTo ) {
    dispatch(getAppointmentsByMonthDetail(values.validFrom,values.validTo));
  }
}, [dispatch, isSubmitting, values.validFrom, values.validTo]);
 
  return (
    <Card sx={{ padding: '1.5rem 1rem', ...sx }}>
      {title && (
        <CardTitle sx={{
          display:'flex',
          alignItems:'flex-start',
          justifyContent:'space-between'
        }}>


          <Box sx={{ flex: 1 }} >

            <InputLabel id="demo-simple-select-label" > {translate('from')}</InputLabel>


            <FormControl sx={{ mt: '8px' }} fullWidth>
              <TextField
                inputProps={{
                    max: today,
                }}

                fullWidth
                type='date'
              {...getFieldProps('validFrom')}
              error={Boolean(touched.validFrom && errors.validFrom)}
              />

              <FormHelperText error={true}>{touched.validFrom && errors.validFrom}</FormHelperText>

            </FormControl>

          </Box>

          <Box sx={{ flex: 1 }} >
            <InputLabel id="demo-simple-select-label" >{translate('to')}</InputLabel>


            <FormControl sx={{ mt: '8px' }} fullWidth>
              <TextField
                inputProps={{
                    min: values?.validFrom,
                }}

                fullWidth
                type='date'
              {...getFieldProps('validTo')}
              error={Boolean(touched.validTo && errors.validTo)}
              disabled={!values?.validFrom}
              />

              <FormHelperText error={true}>{touched.validTo && errors.validTo}</FormHelperText>

            </FormControl>
          </Box>

          <Box>
            <IconButton 
            disabled={(values.validFrom && values.validTo) ? false:true}
            onClick={resetAppointmentCalender}>
              <Restore/>
            </IconButton>
          </Box>
         


        </CardTitle>
      )}
      {children}
      <CardTitle>
          <SubTitle>{title }</SubTitle>
          {statistics && (
            <NumberText>
              {' '}
          
                {/* @ts-ignore */}
                <CountUp end={statistics} duration={1} separator={','} />
                {` ${statisticsText || ''}`}
            </NumberText>
          )}
        </CardTitle>
    </Card>
  );
}
