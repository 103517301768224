import React from 'react';
import { Box, Stack, IconButton, Typography, useTheme } from '@mui/material';
import useLocales from 'hooks/useLocales';
import { IMAGE_URL } from 'utils/axios';
import Close from '@mui/icons-material/Close';
import { Assignment, Download } from '@mui/icons-material';
import { Avatar } from '@mui/material';

export const NewFileViewer = ({ fileName }: { fileName: any }) => {
  const { currentLang } = useLocales();

  const fileFirstName = fileName.split('.')[0];
  const fileSecondName = fileName.split('.')[1];
  const theme = useTheme();




  return (
    <Stack
      
      sx={{
        bgcolor: theme.palette.background.default,
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
      flexDirection={ 'row-reverse' } 
      alignItems={'center'}
      gap={1}
    >
      <Stack width={'80%'} direction={'row-reverse'}  alignItems={'center'} gap={1} flexWrap={'wrap'} >
      <Avatar sx={{ bgcolor: theme.palette.primary.main  }} variant="square">
        <Assignment
          sx={{
            color: 'white',
            my:1
          }}
        />
      </Avatar>
      <Typography variant='body2' >
        {fileFirstName.slice(0, 5)}
        {'.'}
        {fileSecondName}
      </Typography>
      </Stack>

      <IconButton
        onClick={() => {
          window.open(IMAGE_URL + fileName, '_blank', 'noopener,noreferrer');
        }}
        sx={{
          width:'20%',
          display: 'flex',
          justifyContent: currentLang.value === 'ar' ? 'flex-start' : 'flex-end'
        }}
      >
            <Download
            
            sx={{
              color: theme.palette.grey[500],
              cursor:'pointer'
            }}
          />
      </IconButton>
    </Stack>
  );
};
