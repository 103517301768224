import { Card, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { CSSProperties, styled } from '@mui/system';
import LoadingTable from 'components/LoadingTable';
import Scrollbar from 'components/Scrollbar';
import { useCallback, useEffect, useState } from 'react';
import { getUpCommingAppointmentList } from 'redux/slices/appointment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import EmptyTable from 'components/EmptyTable';
import moment from 'moment';
import { AccessTime } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { getAppointmentAndRequestListWithInvoice } from 'redux/slices/report';
import { masterRequestStatusOptions } from 'utils/enums';


const TableLink:any = styled(Link)(({ theme }:{ theme:any }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
})); 


export default function RequestWithInvoice({ style,referenceType }: { style?: CSSProperties,referenceType:number }) {
  const dispatch = useDispatch();
  const [isLoading,setIsLoading] = useState(true);
  const [listData,setListData] = useState<any>([])
  const appointmentData = useSelector(
    (state: RootState) => state.appointment.UpcommingAppointmentList
  );



  const getDataAsync = useCallback(async()=>{
     
    setIsLoading(true)
     setListData(await dispatch(getAppointmentAndRequestListWithInvoice(referenceType)));
    setIsLoading(false)
  },[dispatch,referenceType])

  const linkGenerator = (referenceId:any,status:any)=>{

    if(referenceType===0)
    {
      return `${PATH_DASHBOARD.appointments.admin}/${referenceId}/details/${0}`
    }
    else 
    {
       return `${PATH_DASHBOARD.request.root}/masterRequest/${status}/${referenceId}/detail/${0}`   
    }
    

  }

  useEffect(() => {
    getDataAsync();
  }, [getDataAsync]);

  return (
    <>
      <Scrollbar
        sx={{
          width: '100%',
          maxWidth: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '300px',
          ...style
        }}
        // @ts-ignore
        scrollStyles={{
          bar: {
            backgroundColor: '#d49e24'
          },
          track: {
            backgroundColor: '#F4F6F8',
            width: 8
          }
        }}
      >

        <TableContainer sx={{ minWidth: '100%' }}>
          <Table>
            {isLoading && listData.length === 0 ? (
              <LoadingTable />
            ) : (
              <TableBody>
                {listData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <EmptyTable message="لا يوجد مواعيد فى الوقت الحالى" />
                    </TableCell>
                  </TableRow>
                ) : (
                  listData?.map((data: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: '12px',
                            color: '#637381'
                          }}
                        >
                          <TableLink to={linkGenerator(data?.referenceId,data?.status)}>
                          {data?.referenceNumber || '-'}
                          </TableLink>
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: '12px'
                          }}
                        >
                          {data?.lawyerName || '-'}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: '12px'
                          }}
                        >
                          {data?.customerName || '-'}
                        </TableCell>

                      
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
}
