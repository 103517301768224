import * as Sentry from '@sentry/react';
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
// import axios from '../../utils/axios';

import { FilesState } from '../../@types/files';
import axios from 'axios';
import { URL } from 'utils/axios';
// @types

// ----------------------------------------------------------------------

const initialState: FilesState = {
  isLoading: false,
  isAssigning: false,
  error: false,
  FilesUrl: []
};

const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }

    //Newly Added request for Monthly Reports

    // getEmployeeListSuccess(state, action) {
    //   state.isLoading = false;
    //   state.EmployeeList = action.payload;
    // },
    // getEmployeeSuccess(state, action) {
    //   state.isLoading = false;
    //   state.Employee = action.payload;
    // },
    // createEmployeeSuccess( state,action )
    // {
    //   state.isLoading = false;
    //   state.createEmployeeStatus = action.payload;
    // },
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------
//Newly Added request for Monthly Reports

//   export function getEmployee(id:any ) {
//     return async () => {
//       dispatch(slice.actions.startLoading());
//       try {

//           const response = await axios.get(`/Employee/GetEmployeeById/${id}`)

//           dispatch(slice.actions.getEmployeeSuccess(response.data.result));

//       } catch (error) {
//         Sentry.captureException(error);
//         dispatch(slice.actions.hasError(true));
//       }
//     };
//   }

let dev_url = 'https://shwraapidevops.azurewebsites.net/';

let prod_url = `${URL}/`;

export function uploadFilesCall(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${prod_url}v2/api/appLawyer/appLawyer.uploadFilesAsync`,
        data
      );
      return { files: response.data, STATUS: true };
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      return { error, STATUS: false };
    }
  };
}

//--------------------------------------------------------------------------------------------
