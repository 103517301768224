import { Income, Incomes } from "../@types/dashboard";

export function getFullName({
    firstName,
    lastName 
  }:{
    firstName: string
    lastName: string
  }){
  
    return `${firstName} ${lastName}`
}

export function getTotalYear(data: [{ year: number; month: number; total: number; }]){
  return (data || [])?.reduce(
    (accumulator:any, currentValue:any) => accumulator + currentValue?.total,
    0,
  )
}

export function getTotalCurrentMonth(data: Incomes | any []){
  const date = new Date();
  return (data || [])?.find((income:Income) => income?.month === date.getMonth()+1)
}

export function getTotalPreMonth(data: any[]  | any []){
  const date = new Date();
  return (data || [])?.find((income:Income) => income?.month === date.getMonth())
}

export function getRes(data1: number, data2: number){
  return (data1-data2)/data1*100.0
}