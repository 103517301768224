import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { Box, Card, Container, Grid, Stack, Typography, Backdrop, CircularProgress, TableContainer, TableBody, TableRow, TableCell, TablePagination, Dialog, DialogContent } from '@mui/material';
import Label from 'components/Label';
import Page from '../../../components/Page';
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import SubTitle from 'components/SubTitles';
import HeaderWithoutTabs from 'components/_dashboard/HeaderWithoutTabs';
import Divider from '@mui/material/Divider';
import { getB2bCompany, getB2bCompanyCustomers } from 'redux/slices/b2b';
import B2bDetailsCard from 'components/_dashboard/b2b/B2bDetailsCard';
import Scrollbar from 'components/Scrollbar';
import { Table } from '@mui/material';
import B2bListHead from './B2bListHead';
import moment from 'moment';
import { MaintenanceIllustration } from 'assets';
import CanGuard from 'guards/CanGuard';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import B2bCompanyCustomersCreate from './B2bCompanyCustomersCreate';
import { B2bCompanyCustomersUpdate } from '../../../@types/b2b';
import B2bCustomerMoreMenu from 'components/_dashboard/b2b/B2bCustomerMoreMenu';
import ChangeCustomerStatusModal from './ChangeCustomerStatusModal';
import useLocales from 'hooks/useLocales';

export default function B2bDetails() {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  function ListEmpty() {
    return (
      <TableRow>
        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>

          <MaintenanceIllustration sx={{ my: 3, height: 300 }} />

          <Typography sx={{ color: 'text.secondary', m: 3 }} >
            لا يوجد موظفين فى الوقت الحالى
          </Typography>

          <CanGuard accessibleRoles={["Client"]}>
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.request.newRequest}
            //startIcon={<Icon icon={plusFill} />}
            >
              انشاء موظف جديد
            </Button>
          </CanGuard>


        </TableCell>
      </TableRow>

    );
  }

  const TABLE_HEAD = [
    { id: 'firstName', label: 'الأسم الأول', alignRight: false },
    { id: 'lastName', label: 'الأسم الأخير', alignRight: false },
    // { id: 'email', label: 'البريد الالكترونى', alignRight: false },
    { id: 'phoneNumber', label: translate('phone number'), alignRight: false },
    { id: 'birthDate', label: 'تاريخ الميلاد', alignRight: false },
    { id: '', label: '', alignRight: false },

  ];

  const { b2bId = '' } = useParams();
  const [selectedB2bCustomer, setselectedB2bCustomer] = useState<B2bCompanyCustomersUpdate>({
    UserId: '',
    FirstName: '',
    LastName: '',
    PhoneNumber: '',
    CountryCode: '',
    Gender: 0,
    BirthDate: '',
    ProfileImage: ''
  });
  const [b2bCustomerId, setB2bCustomerId] = useState('')
  const [modalType, setModalType] = useState<'delete' | ''>('');
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openCompanyCustomersForm, setOpenCompanyCustomersForm] = useState(false)


  const { b2bCompany, isLoading, b2bCompanyCustomers, isB2bCompanyCustomerCreated, isB2bCompanyCustomerUpdated } = useSelector(
    (state: RootState) => state.b2b
  );
  useEffect(() => {

    dispatch(getB2bCompany(b2bId));
    dispatch(getB2bCompanyCustomers(b2bId));

  }, [dispatch, b2bId, isB2bCompanyCustomerCreated, isB2bCompanyCustomerUpdated]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading)
    return (

      <Backdrop sx={{ background: 'transparent !important' }} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    )


  const isEmpty = !isLoading && b2bCompanyCustomers?.items?.length === 0;

  return (
    <Page title={`الشركات: تفاصيل الشركة   | Shwra`}>
      <Container maxWidth={b2bCompany ? false : 'lg'}>

        <HeaderWithoutTabs Heading=' تفاصيل الشركة' Link={PATH_DASHBOARD.b2b.list} />
        <Fragment>
          <Grid container spacing={3} >
            <Grid item xs={12} md={6}>
              <B2bDetailsCard b2bCompany={b2bCompany} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 1 }}>
                <Stack sx={{ mt: '10px' }} direction={{ xs: 'column', sm: 'row' }} mb={4} justifyContent='space-between' >
                  <Typography variant='h4' gutterBottom>موظفين الشركة</Typography>
                  <Button sx={{ ml: 'auto' }}
                    variant="contained"
                    startIcon={<Icon icon={plusFill} width={20} height={20} />}
                    onClick={
                      () => {
                        setB2bCustomerId('')
                        setOpenCompanyCustomersForm(true)
                      }
                    }
                  >
                    موظف جديد
                  </Button>
                </Stack>



                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <B2bListHead headLabel={TABLE_HEAD} rowCount={0} numSelected={0} />
                      <TableBody>
                        {b2bCompanyCustomers?.items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((request) => {
                            const {
                              firstName, lastName, id, birthDate, email, phoneNumber } = request;

                            return (
                              <TableRow hover tabIndex={-1} key={id}>

                                <TableCell align="left">
                                  <Typography
                                    // noWrap
                                    color="warning">
                                    {firstName}
                                  </Typography>
                                </TableCell>

                                <TableCell align="left">
                                  <Typography
                                    color="warning"
                                  >
                                    {lastName}
                                  </Typography>
                                </TableCell>


                                <TableCell align="left">
                                  <Typography
                                    color="warning"
                                  >
                                    {phoneNumber}
                                  </Typography>
                                </TableCell>


                                <TableCell align="left"> {moment(birthDate).locale('en').format((`DD-MM-YYYY `))}</TableCell>
                                <TableCell align='left'>


                                  <Stack direction='row' justifyContent='center' gap={2}>

                                    <B2bCustomerMoreMenu
                                      onDelete={() => {
                                        setOpenStatusModal(true);
                                        setModalType('delete');
                                        setselectedB2bCustomer({
                                          UserId: id,
                                          FirstName: firstName,
                                          LastName: lastName,
                                          PhoneNumber: phoneNumber,
                                          BirthDate: birthDate,
                                          CountryCode: '',
                                          Gender: 0,
                                          ProfileImage: ''
                                        });
                                      }}
                                      userName={id}
                                      setDisplay={setOpenCompanyCustomersForm}
                                      setB2bId={setB2bCustomerId}
                                      b2b={request}
                                      setselectedB2b={setselectedB2bCustomer}
                                    />
                                  </Stack>
                                </TableCell>

                              </TableRow>
                            );
                          })}
                      </TableBody>

                      {isEmpty && <ListEmpty />}
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={b2bCompanyCustomers?.items?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => setPage(page)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
          </Grid>

        </Fragment>
      </Container>
      <Dialog sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: { xs: '80%', md: '60%' },
          }
        }
      }}
        open={openCompanyCustomersForm}>
        <DialogContent sx={{ paddingTop: 0 }} >
          <B2bCompanyCustomersCreate setDisplay={setOpenCompanyCustomersForm} companyId={b2bId} b2b={selectedB2bCustomer} name={b2bCustomerId} />
        </DialogContent>
      </Dialog>
      <ChangeCustomerStatusModal
        type={modalType}
        open={openStatusModal}
        setOpen={setOpenStatusModal}
        b2b={selectedB2bCustomer}
        companyId={b2bId}
      />
    </Page>
  );
}
