import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import suspendFill from '@iconify/icons-ic/block';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import SvgIconStyle from 'components/SvgIconStyle';
import {
  B2bCompanyCustomersUpdate,
  B2bCompanyCustomers,
  B2bUpdateCompanyEmployees
} from '../../../@types/b2b';

type B2bCustomerMoreMenuProps = {
  onDelete: VoidFunction;
  userName: string;
  setDisplay: Function;
  setB2bId: Function;
  b2b: null | B2bCompanyCustomers;
  setselectedB2b: Function;
  setIsAdd: any;
  setCompanyId: any;
};

export default function B2bEmmployeesMoreMenu({
  onDelete,
  userName,
  setDisplay,
  setB2bId,
  setselectedB2b,
  b2b,
  setIsAdd,
  setCompanyId
}: B2bCustomerMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const B2bEditHandler: any = () => {
    setDisplay(true);
    setIsAdd('edit');
    setselectedB2b({
      FirstName: b2b?.firstName,
      LastName: b2b?.lastName,
      PhoneNumber: b2b?.phoneNumber,
      userName: b2b?.userName,
      Email: b2b?.email,
      CompanyId: setCompanyId,
      userId: b2b?.userId
    });
    setB2bId(userName);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            onDelete();
            setIsOpen(false);
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="حذف" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem onClick={B2bEditHandler} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="تحديث" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
