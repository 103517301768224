import { Box, Card, Grid, Stack, Typography, Divider } from '@mui/material';
import { B2bCompanies } from '../../../@types/b2b';
import Label from 'components/Label';
import SubTitle from 'components/SubTitles';
import useLocales from 'hooks/useLocales';

type B2bDetailsCardProps = {
    b2bCompany: null| B2bCompanies;
  };
export default function B2bDetailsCard({ b2bCompany }: B2bDetailsCardProps) {
const { translate } = useLocales();

    return(
    <Box sx={{ mb: { xs: 4, md: 4 } }}>
    <Card
      sx={{
        mb: 3,
        p: { xs: 2, md: 3 },
        position: 'relative',
        width: { xs: '100%', md: '100%', lg: '100%' }
      }}
    >
      <Grid container columnSpacing={5}>

        <Grid sx={{ mb: 1 }} xs={12}>
          <Stack sx={{ py: 1 }} justifyContent='space-between' direction='row' alignItems='center' >
            <Box sx={{ bgcolor: 'background.mainColor', p: 1, mx: 4, borderRadius: 1 }} >
              {/* <Bookmark color='secondary' /> */}
            </Box>
            <Stack direction='row' gap={1} >

              <Label
                variant={b2bCompany?.isActive ? 'filled' : 'ghost'}
                sx={b2bCompany?.isActive ? { bgcolor: 'error.lighter', color: 'secondary.main' } : {}}
              >
                {b2bCompany?.isActive ? 'مفعل' : 'غير مفعل' || ' - '}
              </Label>

            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>



          <Stack direction='row' justifyContent='space-between'>
            <SubTitle>الاسم المختصر</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.shortName || ' - '}
            </Typography>
          </Stack>

          <Divider sx={{ mb: 2 }} />



          <Stack direction='row' justifyContent='space-between'>
            {' '}
            <SubTitle> الاسم الكامل </SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.fullName || ' - '}
            </Typography>
          </Stack>

          <Divider sx={{ mb: 2 }} />


          <Stack direction='row' justifyContent='space-between'>
            {' '}
            <SubTitle>{translate('description')}</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.description || ' - '}
            </Typography>
          </Stack>
          <Divider sx={{ mb: 2 }} />

          <Stack direction='row' justifyContent='space-between'>
            {' '}
            <SubTitle>{translate('email')}</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.contactEmail || ' - '}
            </Typography>
          </Stack>
          <Divider sx={{ mb: 2 }} />


        </Grid>

        <Grid item xs={12} md={6}>



          <Stack direction='row' justifyContent='space-between'>
            <SubTitle>{translate('tax number')}</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.crNumber || ' - '}
            </Typography>
          </Stack>

          <Divider sx={{ mb: 2 }} />



          <Stack direction='row' justifyContent='space-between'>
            {' '}
            <SubTitle>{translate('contact number')}</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.contactNo || ' - '}
            </Typography>
          </Stack>

          <Divider sx={{ mb: 2 }} />


          <Stack direction='row' justifyContent='space-between'>
            {' '}
            <SubTitle>{translate('address')}</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.address || ' - '}
            </Typography>
          </Stack>
          <Divider sx={{ mb: 2 }} />

          <Stack direction='row' justifyContent='space-between'>
            {' '}
            <SubTitle>خط الطول</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.longitude || ' - '}
            </Typography>
          </Stack>
          <Divider sx={{ mb: 2 }} />

          <Stack direction='row' justifyContent='space-between'>
            {' '}
            <SubTitle>خط العرض</SubTitle>
            <Typography variant="body1" display="block" sx={{ py: 1 }}>
              {b2bCompany?.lattitude || ' - '}
            </Typography>
          </Stack>
          <Divider sx={{ mb: 2 }} />


        </Grid>

      </Grid>
    </Card>
  </Box>
)
}