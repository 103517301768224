import * as Yup from 'yup';
import { useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
  InputLabel,
  FormControl
} from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormHeader from '../FormHeader';
import {  createB2bCompanyCustomer, updateB2bCompanyCustomer } from 'redux/slices/b2b';
import { B2bCompanyCustomersUpdate } from '../../../@types/b2b';
import { UploadAvatar } from '../../upload';
import { fData } from '../../../utils/formatNumber';
import ReactSelect from '../../ReactSelect';
import useLocales from 'hooks/useLocales';

type B2bCompanyCustomerFormProps = {
  isEdit: boolean;
  b2bCompany?: null | B2bCompanyCustomersUpdate;
  setDisplay: Function;
  companyId: string

};


export default function B2bCompanyCustomerForm({ isEdit, b2bCompany, setDisplay, companyId }: B2bCompanyCustomerFormProps) {

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const genderOptions = [
    { value: 0, label: 'ذكر' },
    { value: 1, label: 'انثى' }
  ];
  const today = new Date().toISOString().split('T')[0];

  const NewUserSchema = isEdit
    ? Yup.object().shape({
      FirstName: Yup.string().required('الأسم المختصر مطلوب'),
      LastName: Yup.string(),
      PhoneNumber: Yup.string().required('رقم الهاتف مطلوب'),
      Gender: Yup.number(),
      BirthDate: Yup.string(),
      // ProfileImage: Yup.string(),
      CompanyTags: Yup.string(),

    })
    : Yup.object().shape({
      FirstName: Yup.string().required('الأسم المختصر مطلوب'),
      LastName: Yup.string(),
      PhoneNumber: Yup.string().required('رقم الهاتف مطلوب'),
      Gender: Yup.number(),
      BirthDate: Yup.string(),
      // ProfileImage: Yup.string(),
      CompanyTags: Yup.string(),
    });
  const dispatch = useDispatch();
  const { error, isLoading, isB2bCompanyCustomerCreated, updateB2bCustomer,isB2bCompanyCustomerUpdated } = useSelector((state: RootState) => state.b2b);


  useEffect(() => {
    // @ts-ignore
    if (error?.errors) {
      // @ts-ignore
      Object.values(error.errors)
        .flat()
        .map((item: any) => enqueueSnackbar(item, { variant: 'error' }));
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (isEdit) {
      if (isB2bCompanyCustomerUpdated) {
        enqueueSnackbar('تم تعديل الموظف بنجاح', {
          variant: 'success'
        });
        setDisplay(false)
        // dispatch(getB2bCompany(companyId));
      }
    } else {

      if (isB2bCompanyCustomerCreated) {
        enqueueSnackbar('تم اضافة الموظف بنجاح', {
          variant: 'success'
        });
        setDisplay(false)
      }
    }
  }, [enqueueSnackbar, isB2bCompanyCustomerCreated, isEdit, navigate, setDisplay, isB2bCompanyCustomerUpdated, dispatch, companyId]);


  const formik = useFormik({
    initialValues: {
      FirstName: isEdit && b2bCompany ? b2bCompany.FirstName : '',
      LastName: isEdit && b2bCompany ? b2bCompany.LastName : '',
      CountryCode: '',
      PhoneNumber: isEdit && b2bCompany ? b2bCompany.PhoneNumber : '',
      Gender: '',
      BirthDate: isEdit && b2bCompany ? b2bCompany.BirthDate : '',
      ProfileImage: '',
      CompanyTags: '',
      UserId: isEdit && b2bCompany ? b2bCompany.UserId : '',

    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        let bodyFormData = new FormData();
        !isEdit && bodyFormData.append(
          'ProfileImage',
          new Blob([values.ProfileImage], { type: 'application/octet-stream' })
        );
        bodyFormData.append('FirstName', values.FirstName);
        bodyFormData.append('LastName', values.LastName);
        !isEdit && bodyFormData.append('Gender', values.Gender);
        bodyFormData.append('BirthDate', values.BirthDate);
        bodyFormData.append('CountryCode', '966');
        bodyFormData.append('PhoneNumber', values.PhoneNumber);
        bodyFormData.append('CompanyTags', companyId);
        isEdit&&bodyFormData.append('UserId', values.UserId);

        isEdit
          ? await dispatch(updateB2bCompanyCustomer(bodyFormData,companyId))
          : await dispatch(createB2bCompanyCustomer(bodyFormData,companyId));
        resetForm();
        setSubmitting(false);
      } catch (error: any) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } =
    formik;

  const theme = useTheme();
const { translate } = useLocales();

  const useStyles = makeStyles({
    root: {
      '& fieldset': {
        background: formik.isValid && (theme.palette.mode === 'dark'
          ? `${theme.palette.error.darker} !important` : `${theme.palette.error.lighter} !important`)
      },

    },

  })

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('ProfileImage', {
          ...file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFieldValue]
  );
  const classes = useStyles();
  const style = {
    background: theme.palette.mode === 'dark'
      ? `${theme.palette.error.darker} !important` : `${theme.palette.error.lighter} !important`
  }

  return (
    <>
      {
        isEdit && !isSubmitting && isLoading ?
          <Stack sx={{ justifyContent: 'center', alignItems: 'center', pt: '12px' }} >
            <CircularProgress color="secondary" />
          </Stack>
          :
          <FormikProvider value={formik}>
            <FormHeader setDisplay={setDisplay} Heading={!isEdit ? 'انشاء موظف جديد' : 'تعديل بيانات الموظف'} />

            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3} >
               {!isEdit&&
               <Grid item xs={12}>
               <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' }, mt: 3 }} >

                 {/* <Typography variant='h4'>{!isEdit ? 'انشاء محامى جديد' : 'تعديل محامى'}</Typography> */}
                 <Box sx={{ py: 2, px: 3, mx: { xs: 'auto', md: 0 } }}>
                   <Box >
                     <UploadAvatar
                       mainText={translate('profile picture')}
                       accept="image/*"
                       file={values.ProfileImage}
                       maxSize={3145728}
                       onDrop={handleDrop}
                       error={Boolean(touched.ProfileImage && errors.ProfileImage)}
                       caption={
                         <Typography
                           variant="caption"
                           sx={{
                             mt: 2,
                             mx: 'auto',
                             display: 'block',
                             textAlign: 'center',
                             color: 'text.secondary'
                           }}
                         >
                           {translate('allowed formats')}
                           <br />
                           *.jpeg, *.jpg, *.png, *.gif
                           <br /> {translate('maximum image size')} {fData(3145728)}
                         </Typography>
                       }
                     />
                     <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                       {touched.ProfileImage && errors.ProfileImage}
                     </FormHelperText>
                   </Box>
                 </Box>
               </Box>
             </Grid>
               } 

                <Grid item xs={12} md={12}>
                  {/* <Card sx={{ p: 3 }}> */}
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>

                      <Box sx={{ minWidth: '48%' }}>
                        <Typography sx={{ color: 'text.secondary' }}>{translate('first name')}</Typography>
                        <TextField variant='outlined'
                          className={classes.root}
                          fullWidth
                          {...getFieldProps('FirstName')}
                          error={Boolean(touched.FirstName && errors.FirstName)}
                          helperText={touched.FirstName && errors.FirstName}
                        />
                      </Box>

                      <Box sx={{ minWidth: '48%' }} >
                        <Typography sx={{ color: 'text.secondary' }}>{translate('last_name')}</Typography>
                        <TextField
                          className={classes.root}
                          fullWidth
                          {...getFieldProps('LastName')}
                          error={Boolean(touched.LastName && errors.LastName)}
                          helperText={touched.LastName && errors.LastName}
                        />
                      </Box>

                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>


                      <Box sx={{ minWidth: '48%' }}>
                        <Typography sx={{ color: 'text.secondary' }} >
                          {translate('phone number')}
                        </Typography>

                        <TextField
                          className={classes.root}
                          fullWidth
                          label=""
                          {...getFieldProps('PhoneNumber')}
                          error={Boolean(touched.PhoneNumber && errors.PhoneNumber)}
                          helperText={touched.PhoneNumber && errors.PhoneNumber}
                        />
                      </Box>
                      {!isEdit&&
                      <div style={{ minWidth: '48%' }}>
                        <Typography sx={{ color: 'text.secondary' }} >
                          {translate('choose gender')}
                        </Typography>
                        <ReactSelect
                          controleStyles={formik.isValid && style}
                          menuPlacement='top'
                          options={genderOptions}
                          value={formik.values.Gender}
                          onChange={(value: any) => formik.setFieldValue('Gender', value.value)}
                          placeholder=""
                        />
                        {touched.Gender && errors.Gender && (
                          <FormHelperText error sx={{ px: 2 }}>
                            {touched.Gender && errors.Gender}
                          </FormHelperText>
                        )}
                      </div>
                      }

                    </Stack>
                    {!isEdit&&
                    <Grid item xs={12} md={6}>
                      <Box >
                        <InputLabel id="demo-simple-select-label" >تاريخ الميلاد</InputLabel>


                        <FormControl sx={{ mt: '8px' }} fullWidth>
                          <TextField
                            inputProps={{
                              max: today,
                            }}

                            fullWidth
                            type='date'
                            {...getFieldProps('BirthDate')}
                            error={Boolean(touched.BirthDate && errors.BirthDate)}
                            helperText={touched.BirthDate && errors.BirthDate}
                          />

                          <FormHelperText error={true}>{touched.BirthDate && errors.BirthDate}</FormHelperText>

                        </FormControl>
                      </Box>
                    </Grid>

                    }




                  </Stack>
                </Grid>






                <Grid item xs={12} >

                  <LoadingButton
                    disabled={!formik.isValid}
                    type="submit" variant="contained" loading={isSubmitting}>
                    {!isEdit ? translate('create') : 'تعديل'}
                  </LoadingButton>
                </Grid>

              </Grid>
            </Form>
          </FormikProvider>

      }
    </>
  );
}
