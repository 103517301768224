import { Link as RouterLink } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';

// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import LoginBgLogo from '../../assets/Images/LoginBgLogo.svg';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    backgroundImage:
      'linear-gradient(178deg, #F4F5F800 0%, #F4F5F8 100%),url(https://upload.wikimedia.org/wikipedia/commons/3/33/Badshahi_Mosque_%28black%26white%29.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  overflow: 'auto',
  height: '100vh'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '500px',
  display: 'flex',
  minHeight: '648px',
  flexDirection: 'column',
  padding: theme.spacing(2, 2),
  borderRadius: '24px',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    margin: 'auto',
    minHeight: '100vh',
    width: '100%'
  }
}));

//------------------Sx Style for Box With Shwra Logo----------

const LogoBoxStyle: {} = {
  width: '100%',
  margin: 'auto',
  height: '100vh',
  position: 'absolute',
  overflow: 'hidden',
  display: { xs: 'none', md: 'flex' },
  justifyContent: 'end',
  alignItems: 'center',
  opacity: 0.2
};

// ------------------Container Styling---------------------------

const ContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: { xs: 'background.default', sm: 'transparent' }
};

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="تسجيل الدخول | شورى">
      <Container sx={ContainerStyle} maxWidth="xl">
        <ContentStyle sx={{ backgroundColor: 'background.default' }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box>
              <img style={{ width: '73px' }} alt="register" src="/logo.svg" />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography color="secondary" variant="h4" gutterBottom>
                | شورى للاعمال
              </Typography>
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                تسجيل الدخول
              </Typography>
            </Box>
          </Stack>

          <LoginForm />
          <Box>
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              {'ليس لدى حساب؟'}&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                ابدأ الان
              </Link>
            </Typography>
          </Box>
        </ContentStyle>
      </Container>

      <Box sx={LogoBoxStyle}>
        <img width="1200px" height="800px" src={LoginBgLogo} alt=" " />
      </Box>
    </RootStyle>
  );
}
