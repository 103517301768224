import * as Sentry from '@sentry/react';
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
// @types
import { RequestState, Request } from '../../@types/request';
// ----------------------------------------------------------------------

const initialState: RequestState = {
  isLoading: false,
  isAssigning: false,

  error: false,
  requestList: [],
  //new
  masterRequestList: [],
  extraServicesList: [],
  MasterRequestApprovedById: {},
  MasterRequestById: {},
  masterRequestApprovedList: [],
  activeFilteredMasterRequestList: [],
  messageSending: false,

  request: null,

  requestStandardList: [],
  requestTypeList: [],
  requestActionList: [],
  requestStatus: {},
  monthlyReports: [],
  createMasterRequestListSuccess: [],
  UpdateBidsSuccesRequest: [],
  totalRequests: 0,

  cancelAdminMasterRequestStatus: {},
  charityMasterRequestList: [],
  approveMasterRequestListSuccess: [],
  allBids: [],
  createBidByAdminStatus: {},
  changeInvoiceStatus: {},
  reloadHandler: {},
  activeRequestTab: sessionStorage.getItem('MasterRequestStatus') || 'New'
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Create Master Request By Admin

    createMasterRequestByAdminSuccess(state, action) {
      state.isLoading = false;
      state.createMasterRequestListSuccess = action.payload;
    },
    setUpdateBidsSuccesRequest(state, action) {
      state.UpdateBidsSuccesRequest = action.payload;
    },

    approveMasterRequestByAdminSuccess(state, action) {
      state.isLoading = false;
      state.approveMasterRequestListSuccess = action.payload;
    },

    // GET MANAGE Request
    getRequestListListSuccess(state, action) {
      state.isLoading = false;
      state.requestList = action.payload;
    },
    getMasterRequestListSuccess(state, action) {
      state.isLoading = false;
      state.masterRequestList = action.payload;
    },
    getCharityMasterRequestListSuccess(state, action) {
      state.isLoading = false;
      state.charityMasterRequestList = action.payload;
    },
    reseState(state) {
      state.approveMasterRequestListSuccess = [];
    },
    cancelAdminMasterRequestStatus(state, action) {
      state.isLoading = false;
      state.cancelAdminMasterRequestStatus = action.payload;
    },
    // for extra services
    getExtraServiceListSuccess(state, action) {
      state.isLoading = false;
      state.extraServicesList = action.payload;
    },

    getMasterRequestApproved(state, action) {
      state.isLoading = false;
      state.masterRequestApprovedList = action.payload;
    },

    //New added api for master request approved
    getMasterRequestApprovedByIdSuccess(state, action) {
      state.isLoading = false;
      state.MasterRequestApprovedById = action.payload;
    },

    // to get detail of single master request list
    getMasterRequestByIdScuccess(state, action) {
      state.isLoading = false;
      state.MasterRequestById = action.payload;
    },

    // For Message Sent Successfulyy

    sendMessageSuccess(state, action) {
      state.messageSending = action.payload;
    },
    // GET MANAGE Request
    getClientRequestListSuccess(state, action) {
      state.isLoading = false;
      state.requestList = action.payload;
    },

    // GET MANAGE Request
    getAdminRequestListSuccess(state, action) {
      state.isLoading = false;
      state.requestList = action.payload;
    },

    // GET MANAGE Request
    getLawyerRequestListSuccess(state, action) {
      state.isLoading = false;
      state.requestList = action.payload;
    },
    setActiveFilteredMasterRequestListSuccess(state, action) {
      state.activeFilteredMasterRequestList = action.payload;
    },

    // GET Request
    getRequestSuccess(state, action) {
      state.isLoading = false;
      state.request = action.payload;
    },

    //  Requst dynamic
    createRequestSuccess(state, action) {
      state.isLoading = false;
      state.requestStatus = action.payload;
    },

    // GET MANAGE Request
    getRequestStandardsSuccess(state, action) {
      state.isLoading = false;
      state.requestStandardList = action.payload;
    },

    // GET MANAGE Request
    getRequestTypesSuccess(state, action) {
      state.isLoading = false;
      state.requestTypeList = action.payload;
    },

    // GET MANAGE Request
    getRequestActionListSuccess(state, action) {
      state.isLoading = false;
      state.requestActionList = action.payload;
    },

    // GET MANAGE Request
    changeRequestStateSuccess(state, action) {
      state.isLoading = false;

      const { request } = state;

      const status: any = find(
        state.requestActionList,
        (actionRecord: any) => actionRecord.id === Number(action.payload.actionId)
      );

      state.request = {
        ...request,
        status: status.name
      };
    },

    /**
     * @assing
     */
    assignLawyerToRequestSuccess(state, action) {
      state.isLoading = false;

      const { request } = state;

      const requestAssignLawyer: Request = {
        ...(request as Request),
        lawyer: action.payload
      };

      state.request = requestAssignLawyer;
    },

    /**
     * @comments
     */
    createRequestCommentSuccess(state, action) {
      state.isLoading = false;

      const { request } = state;

      state.request = {
        ...(request as Request),
        comments: [...(request.comments as any), action.payload]
      };
    },

    updateRequestCommentSuccess(state, action) {
      state.isLoading = false;

      const { request } = state;

      const requestNewComent: Request = {
        ...(request as Request),
        lawyer: action.payload
      };

      state.request = requestNewComent;
    },
    deleteCommentRequestSuccess(state, action) {
      state.isLoading = false;

      const { request } = state;

      const requestNewComent: Request = {
        ...(request as Request),
        lawyer: action.payload
      };

      state.request = requestNewComent;
    },

    //Newly Added request for Monthly Reports

    getMonthlyReportSuccess(state, action) {
      state.isLoading = false;
      state.monthlyReports = action.payload;
    },

    getBidsByMasterRequestIdSuccess(state, action) {
      state.isLoading = false;
      state.allBids = action.payload;
    },

    createBidByAdminSuccess(state, action) {
      state.isLoading = false;
      state.createBidByAdminStatus = action.payload;
    },

    changeInvoiceStatusSuccess(state, action) {
      state.isLoading = false;
      state.changeInvoiceStatus = action.payload;
    },
    reloadHandlerSuccess(state, action) {
      state.isLoading = false;
      state.reloadHandler = action.payload;
    },

    setActiveTabSuccess(state, action) {
      state.activeRequestTab = action.payload;
    },

    setTotalRequests(state, action) {
      state.totalRequests = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------

export function getRequest({ requestId }: { requestId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/Requests/${requestId}`);
      dispatch(slice.actions.getRequestSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//------------------------------------------------------------------------

export function getClientRequestList({ clientId }: { clientId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Clients/${clientId}/requests`);

      dispatch(slice.actions.getClientRequestListSuccess(response.data?.result?.requests));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//------------------------------------------------------------------------

export function getAdminRequestList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/Requests', {
        params: {
          page: 1,
          size: 1000
        }
      });
      dispatch(slice.actions.getAdminRequestListSuccess(response.data?.result?.requests));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}
export function setAdminRequestList(data: any) {
  return dispatch(slice.actions.getAdminRequestListSuccess(data));
}
//------------------------------------------------------------------------
//-----------------------New Master Request List Api Call
export function getAdminMasterRequestList(
  reqType: number,
  status: number,
  page = 1,
  size = 10,
  MasterRequestType?: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/AppAdmin/MasterRequestList', {
        params: {
          page,
          size,
          RequestType: reqType,
          Status: status,
          MasterRequestType
        }
      });
      dispatch(slice.actions.getMasterRequestListSuccess(response.data.result?.data));
      dispatch(
        slice.actions.setActiveFilteredMasterRequestListSuccess(response?.data.result?.data)
      );
      dispatch(slice.actions.setTotalRequests(response?.data.result?.totalCount));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getACharitydminMasterRequestList(reqType: number = 0) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/AppAdmin/MasterRequestList', {
        params: {
          page: 1,
          size: 1000,
          MasterRequestType: 'charity'
        }
      });
      dispatch(slice.actions.getCharityMasterRequestListSuccess(response.data.result?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function cancelAdminMasterRequest(MasterRequestId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/MasterRequest/CancelMasterRequest?MasterRequestId=${MasterRequestId}`
      );
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.cancelAdminMasterRequestStatus({ status: 'error', error: response?.data })
        );
      } else
        dispatch(
          slice.actions.cancelAdminMasterRequestStatus({
            status: 'success',
            ...response?.data?.result
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.cancelAdminMasterRequestStatus({ status: 'error', error: error }));
    }
  };
}

export function getExtraServicesList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/MasterRequest/MasterRequestList', {
        params: {
          page: 1,
          size: 1000,
          RequestType: 1
        }
      });
      dispatch(slice.actions.getExtraServiceListSuccess(response.data.result?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getApprovedMasterRequests(
  reqType: number,
  status: number,
  page = 1,
  size = 10,
  MasterRequestType?: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/AppAdmin/MasterRequestList', {
        params: {
          page,
          size,
          RequestType: reqType,
          Status: status,
          MasterRequestType
        }
      });
      dispatch(slice.actions.getMasterRequestApproved(response.data.result?.data));
      dispatch(
        slice.actions.setActiveFilteredMasterRequestListSuccess(response?.data.result?.data)
      );
      dispatch(slice.actions.setTotalRequests(response?.data.result?.totalCount));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function setAdminMasterRequestList(data: any) {
  return dispatch(slice.actions.getMasterRequestListSuccess(data));
}

export function getMasterRequestApprovedById(Id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/MasterRequestApproved/MasterRequestApprovedGetById', {
        params: {
          MasterRequestApprovedId: Id
        }
      });
      dispatch(slice.actions.getMasterRequestApprovedByIdSuccess(response.data.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function getMasterRequestById(Id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/MasterRequest/GetByIdMasterRequest', {
        params: {
          MasterRequestId: Id
        }
      });
      dispatch(slice.actions.getMasterRequestByIdScuccess(response.data.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function setActiveFilteredMasterRequestList(data: any) {
  return dispatch(slice.actions.setActiveFilteredMasterRequestListSuccess(data));
}
export function setMasterRequestApprovedById(data: any) {
  return dispatch(slice.actions.getMasterRequestApprovedByIdSuccess(data));
}

//----------For Creating Master Request---------------------//

export function createMasterRequestByAdmin(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/CreateMasterRequest`, data);
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.createMasterRequestByAdminSuccess({
            status: 'error',
            error: response?.data
          })
        );
      } else
        dispatch(
          slice.actions.createMasterRequestByAdminSuccess({
            status: 'success',
            ...response?.data?.result
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.createMasterRequestByAdminSuccess({ status: 'error', error: error }));
    }
  };
}

export function UpdateBidsByAdmin(data: any) {
  return async (dispatch: any) => {
    // Add proper type for dispatch
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/AppAdmin/UpdateBidByAdmin', data);
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.setUpdateBidsSuccesRequest({
            status: 'error',
            error: response?.data
          })
        );
        return response.data; // Return the response
      } else {
        dispatch(
          slice.actions.setUpdateBidsSuccesRequest({
            status: 'success',
            ...response?.data?.result
          })
        );
        return response.data; // Return the response
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setUpdateBidsSuccesRequest({ status: 'error', error: error }));
      return { status: false, error }; // Return error response
    }
  };
}

export function ChangeMasterRequestLawyer({
  LawyerId,
  masterRequestId,
  comments
}: {
  LawyerId: any;
  masterRequestId: any;
  comments: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        'AppAdmin/ChangeMasterRequestLawyer',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            accept: '*/*'
          },
          params: {
            LawyerId: LawyerId,
            MasterRequestId: masterRequestId,
            Comments: comments
          }
        }
      );

      // dispatch(slice.actions.getMasterRequestApproved(response.data.result?.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function approveMasterRequestByAdmin(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/AdminMasterRequestApprove`, data);
      if (response?.data?.status === false) {
        dispatch(
          slice.actions.approveMasterRequestByAdminSuccess({
            status: 'error',
            error: response?.data
          })
        );
        dispatch(slice.actions.reseState());
      } else
        dispatch(
          slice.actions.approveMasterRequestByAdminSuccess({
            status: 'success',
            ...response?.data?.result
          })
        );
      dispatch(getACharitydminMasterRequestList());
      dispatch(slice.actions.reseState());
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.approveMasterRequestByAdminSuccess({ status: 'error', error: error }));
      dispatch(slice.actions.reseState());
    }
  };
}

//----------------------For Messages By Admin------------------------------------------

export const sendMessageByAdmin = async (FormData: any) => {
  dispatch(slice.actions.sendMessageSuccess(true));

  let response;
  try {
    response = await axios.post(
      '/MasterRequestApproved/MasterRequestApprovedChatMessage',
      FormData
    );

    dispatch(slice.actions.sendMessageSuccess(false));
  } catch (error) {
    Sentry.captureException(error);
    dispatch(slice.actions.hasError(true));
    dispatch(slice.actions.sendMessageSuccess(false));
  }
  return response;
};
//-------------------------------------------------------------------------------------

export function getLawyerRequestList({ lawyerId }: { lawyerId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Lawyers/${lawyerId}/requests`);

      dispatch(slice.actions.getLawyerRequestListSuccess(response.data?.result?.requests));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setLawyerRequestList(data: any) {
  return dispatch(slice.actions.getLawyerRequestListSuccess(data));
}
//------------------------------------------------------------------------

export function createClientRequest(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.setErrorDefault());
    try {
      let formData = new FormData();

      formData.append('Subject', data.Subject);
      formData.append('Details', data.Details);
      formData.append('TypeId', data.TypeId);
      formData.append('SubTypeId', data.SubTypeId);
      formData.append('StandardId', data.StandardId);
      formData.append('ClientId', data.ClientId);

      data.Attachments.forEach((file: any) => {
        formData.append('Files', file);
      });

      const response = await axios.post('/Requests', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(true));
        dispatch(slice.actions.createRequestSuccess({ status: 'error', error: response?.data }));
      } else
        dispatch(
          slice.actions.createRequestSuccess({
            status: 'success',
            ...response.data?.result?.requests
          })
        );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
      dispatch(slice.actions.createRequestSuccess({ status: 'error', error }));
    }
  };
}

export function setRequestStatue(data: any) {
  return dispatch(slice.actions.createRequestSuccess(data));
}

//------------------------------------------------------------------------

export function getRequestTypes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Lookups/RequestTypes`);

      dispatch(slice.actions.getRequestTypesSuccess(response.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//------------------------------------------------------------------------

export function getRequestStandards() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Lookups/RequestStandards`);

      dispatch(slice.actions.getRequestStandardsSuccess(response.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

/***
 * @Request manage request status
 */
//------------------------------------------------------------------------

export function getRequestActionList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Lookups/RequestActions`);

      dispatch(slice.actions.getRequestActionListSuccess(response.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//------------------------------------------------------------------------

export function changeRequestState({
  requestId,
  actionId
}: {
  requestId: string;
  actionId: string;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/Requests/${requestId}/${actionId}`);
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(true));
      } else dispatch(slice.actions.changeRequestStateSuccess({ actionId }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//----------------------------------------------------------------------------------------------
export function assignLawyerToRequest({
  requestId,
  lawyerId
}: {
  requestId: string;
  lawyerId: string;
}) {
  return async () => {
    dispatch(slice.actions.startAssignment());
    try {
      const Response = await axios.post(`/Requests/${requestId}/${lawyerId}`);
      if (Response?.data?.status === false) {
        dispatch(slice.actions.hasError(true));
      } else {
        const response = await axios.get(`/Lawyers/${lawyerId}`);
        dispatch(slice.actions.assignLawyerToRequestSuccess(response.data?.result));
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//----------------------------------------------------------------------------------------------

export function createRequestComment(data: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      // let formData = new FormData();

      // formData.append("MasterRequestId", requestId );
      // formData.append("Body", body );

      // Attachments.forEach((file: any) =>{
      //   formData.append("Files", file);
      // });

      const response = await axios.post('/Comments/CreateCommentForMasterRequest', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      dispatch(slice.actions.reloadHandlerSuccess('reload on new comment added' + Date.now()));

      return { Status: true, ...response?.data };
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
      return { Status: false, error };
    }
  };
}

export function getRequestComment(requestId: string) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const comment = await axios.get(`/Comments`, {
        params: {
          id: requestId
        }
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//----------------------------------------------------------------------------------------
export function updateRequestComment({
  requestId,
  body,
  file
}: {
  requestId: string;
  body: string;
  file: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/Comments');

      dispatch(slice.actions.updateRequestCommentSuccess(response.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

//----------------------------------------------------------------------------------------
export function deleteCommentRequest({ commentId }: { commentId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/Comments/${commentId}`);

      dispatch(slice.actions.deleteCommentRequestSuccess(response.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function setcancelAdminMasterRequestStatus(data: any) {
  return dispatch(slice.actions.cancelAdminMasterRequestStatus(data));
}

export function getBidsByMasterRequestId(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let formData = new FormData();

      formData.append('MasterRequestId', id);

      const response = await axios.post(`/AppAdmin/GetAllBidsbyMasterRequestId`, formData);

      dispatch(slice.actions.getBidsByMasterRequestIdSuccess(response.data));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function approveAppropriateBid(bidDetail: any, employeeId?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let formData = new FormData();
      formData.append('BidId', bidDetail.bidId);
      formData.append('MasterRequestId', bidDetail.masterRequestId);
      formData.append('LawwyerId', bidDetail.lawyerId);
      formData.append('BidPrice', bidDetail.bidPrice);
      formData.append('BidDetail', bidDetail.bidDetail);
      formData.append('RequestFulFillTimeInDays', bidDetail.requestFulFillTimeInDays);
      formData.append('RequestFulFillDateTime', bidDetail.requestFulFillDateTime);
      formData.append('RequestFulFillDateTime', bidDetail.requestFulFillDateTime);
      formData.append('EmployeeId', employeeId);

      const response = await axios.post(`/AppAdmin/ChooseAppropriateLawyerBid`, formData);

      dispatch(slice.actions.reloadHandlerSuccess('reload due to approprraite bid' + Date.now()));

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
    }
  };
}

export function makeCallToCustomer(adminNumber: string, referenceId: string, type: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/CallCenter/InitiateCallToCustommer`, {
        params: {
          MobileNumberForAdmin: adminNumber,
          ReferenceId: referenceId,
          type: type
        }
      });

      dispatch(slice.actions.stopLoading());

      return { Status: true, ...response?.data };
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
      return { Status: false, error };
    }
  };
}

export function createBidByAdmin(values: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/AppAdmin/CreateBidByAdmin', values);
      // dispatch(slice.actions.getMasterRequestByIdScuccess(response.data.result));
      dispatch(slice.actions.createBidByAdminSuccess({ status: true, ...response?.data }));

      return response?.data;
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
      return error;
    }
  };
}

export function changeInvoiceStatusByAdmin(invoiceDetail: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/ChangeStatusOfInvoice`, invoiceDetail);
      dispatch(slice.actions.reloadHandlerSuccess('reload due to invoice approve' + Date.now()));
      return { STATUS: true, ...response.data };

      // dispatch(slice.actions.changeInvoiceStatusSuccess({ STATUS:true, ...response.data }))
    } catch (error) {
      // dispatch(slice.actions.changeInvoiceStatusSuccess({ STATUS:false,error }))
      dispatch(slice.actions.reloadHandlerSuccess('reload due to invoice approve' + Date.now()));

      Sentry.captureException(error);
      dispatch(slice.actions.hasError(true));
      return { STATUS: false, error };
    }
  };
}

export function activeRequestTabHandler(activeTab: string) {
  return async () => {
    sessionStorage.setItem('MasterRequestStatus', activeTab);
    dispatch(slice.actions.setActiveTabSuccess(activeTab));
  };
}
//--------------------------------------------------------------------------------------------
