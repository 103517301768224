import { useEffect, useState } from "react"
import useAuth from "./useAuth";

export const useDetectInActivity = ()=> {

  const { logout } = useAuth();
    useEffect(() => {

        let timeoutId:any;
        const InActive_Time_Limit = 60000 * 30;
        function resetTimeout() {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
if(window.localStorage.getItem('accessToken'))
          
            logout();
          },InActive_Time_Limit); // 5 minutes in milliseconds
        }
    
        document.addEventListener('mousemove', resetTimeout);
        document.addEventListener('mousedown', resetTimeout);
        document.addEventListener('keypress', resetTimeout);
        document.addEventListener('touchmove', resetTimeout);
    
        resetTimeout();
    
        return () => {
          document.removeEventListener('mousemove', resetTimeout);
          document.removeEventListener('mousedown', resetTimeout);
          document.removeEventListener('keypress', resetTimeout);
          document.removeEventListener('touchmove', resetTimeout);
          clearTimeout(timeoutId);
        };
        
      }, [logout]);    
} 


