import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormHeader from '../FormHeader';
import {
  createB2bCompanyCustomer,
  createEmployeeB2b,
  setDeleteEmployee,
  SetIsEmpCreated,
  setIsEmployeeUpdated,
  setIsPasswordUpdated,
  updateB2bCompanyCustomer,
  UpdateEmployeeB2b,
  updatePasswordEmployeeB2b
} from 'redux/slices/b2b';
import { B2bCompanyCustomersUpdate, B2bUpdateCompanyEmployees } from '../../../@types/b2b';
import { UploadAvatar } from '../../upload';
import { fData } from '../../../utils/formatNumber';
import ReactSelect from '../../ReactSelect';
import useLocales from 'hooks/useLocales';

type B2bCompanyCustomerFormProps = {
  isEdit: boolean;
  b2bCompany?: null | B2bUpdateCompanyEmployees;
  setDisplay: Function;
  companyId: string;
};

type Employee = {
  errors: [];
  result: any;
  status: any;
};

export default function B2bEmployeesCreatingForm({
  isEdit,
  b2bCompany,
  setDisplay,
  companyId
}: B2bCompanyCustomerFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const genderOptions = [
    { value: 0, label: 'ذكر' },
    { value: 1, label: 'انثى' }
  ];
  const today = new Date().toISOString().split('T')[0];

  const NewUserSchema = isEdit
    ? Yup.object().shape({
        FirstName: Yup.string().required('الأسم المختصر مطلوب'),
        LastName: Yup.string().required('الاسم الأخير مطلوب'),
        UserName: Yup.string().required('اسم المستخدم مطلوب'),
        Email: Yup.string().email('البريد الالكتروني غير صحيح').required('البريد الالكتروني مطلوب'),
        // Password: Yup.string()
        //   .required(translate('Password required'))
        //   .min(6, translate('Password must be at least 6 characters'))
        //   .matches(
        //     /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        //     translate(
        //       'Password must contain at least one uppercase letter, one lowercase letter, and one special character'
        //     )
        //   ),
        // ConfirmPassword: Yup.string()
        //   .oneOf([Yup.ref('Password'), null], 'كلمات المرور غير متطابقة')
        //   .required('تأكيد كلمة المرور مطلوب'),
        // CountryCode: Yup.string().required('رمز البلد مطلوب'),
        PhoneNumber: Yup.string()
          .required('رقم الهاتف مطلوب')
          .matches(/^\d{1,10}$/, 'يجب أن يكون رقم الهاتف من 1 إلى 10 أرقام فقط')
        // Gender: Yup.number().required('الجنس مطلوب'),
        // BirthDate: Yup.string().required('تاريخ الميلاد مطلوب'), // Ensure BirthDate is required
        // CompanyTags: Yup.string()
      })
    : Yup.object().shape({
        FirstName: Yup.string().required('الأسم المختصر مطلوب'),
        LastName: Yup.string().required('الاسم الأخير مطلوب'),
        UserName: Yup.string().required('اسم المستخدم مطلوب'),
        Email: Yup.string().email('البريد الالكتروني غير صحيح').required('البريد الالكتروني مطلوب'),
        Password: Yup.string()
          .required(translate('Password required'))
          .min(6, translate('Password must be at least 6 characters'))
          .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
            translate(
              'Password must contain at least one uppercase letter, one lowercase letter, and one special character'
            )
          ),
        ConfirmPassword: Yup.string()
          .oneOf([Yup.ref('Password'), null], 'كلمات المرور غير متطابقة')
          .required('تأكيد كلمة المرور مطلوب'),
        CountryCode: Yup.string().required('رمز البلد مطلوب'),
        PhoneNumber: Yup.string()
          .required('رقم الهاتف مطلوب')
          .matches(/^\d{1,10}$/, 'يجب أن يكون رقم الهاتف من 1 إلى 10 أرقام فقط'),
        Gender: Yup.number().required('الجنس مطلوب'),
        BirthDate: Yup.string().required('تاريخ الميلاد مطلوب'), // Ensure BirthDate is required
        CompanyTags: Yup.string()
      });
  const ChangePasswordSchema = Yup.object().shape({
    Password: Yup.string()
      .required(translate('Password required'))
      .min(6, translate('Password must be at least 6 characters'))
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
        translate(
          'Password must contain at least one uppercase letter, one lowercase letter, and one special character'
        )
      ),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref('Password'), null], 'كلمات المرور غير متطابقة')
      .required('تأكيد كلمة المرور مطلوب')
  });
  const dispatch = useDispatch();
  const [changePassword, setChangePassword] = useState(false);
  const {
    error,
    isLoading,
    registerEmployee,
    isEmpCreated,
    isEmployeeUpdated,
    updateEmployee,
    isDeleteEmployee,
    deleteEmployee,
    showError,
    isPasswordUpdated,
    showSuccessMessage
  } = useSelector((state: RootState) => state.b2b);

  useEffect(() => {
    if (isEmpCreated === true || isEmployeeUpdated === true || isDeleteEmployee === true) {
      // Check if employee was created
      if (isEmpCreated) {
        if (registerEmployee) {
          enqueueSnackbar(showSuccessMessage, { variant: 'success' });
          setDisplay(false);
        }
        SetIsEmpCreated(null);
      }

      // Check if employee was updated
      if (isEmployeeUpdated) {
        if (updateEmployee) {
          enqueueSnackbar(showSuccessMessage, { variant: 'success' });
          setDisplay(false);
        }
        setIsEmployeeUpdated(null);
      }
    }
  }, [
    isEmpCreated,
    isEmployeeUpdated,
    registerEmployee,
    updateEmployee,
    setDisplay,
    enqueueSnackbar,
    error,
    isDeleteEmployee,
    deleteEmployee,
    showSuccessMessage
  ]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isEmpCreated === false) {
      enqueueSnackbar(showError, { variant: 'error' });
      setDisplay(false);
    } else if (isEmployeeUpdated === false) {
      enqueueSnackbar(showError, { variant: 'error' });
      setDisplay(false);
    }
    SetIsEmpCreated(null);
    setIsEmployeeUpdated(null);
  }, [isEmpCreated, enqueueSnackbar, error, showError]);

  useEffect(() => {
    if (isPasswordUpdated === false) {
      enqueueSnackbar(showError, { variant: 'error' });
      setDisplay(false);
    } else if (isPasswordUpdated === true) {
      enqueueSnackbar(showSuccessMessage, { variant: 'success' });
      setDisplay(false);
    }
    setIsPasswordUpdated(null);
  }, [isPasswordUpdated, enqueueSnackbar, showError]);

  /* eslint-enable react-hooks/exhaustive-deps */

  const formik = useFormik({
    initialValues: {
      FirstName: isEdit && b2bCompany ? b2bCompany.FirstName : '',
      LastName: isEdit && b2bCompany ? b2bCompany.LastName : '',
      UserName: !isEdit && b2bCompany ? '' : isEdit && b2bCompany ? b2bCompany.userName : '',
      Email: isEdit && b2bCompany ? b2bCompany.Email : '',
      Password: '', // Will be conditionally appended
      ConfirmPassword: '', // Will be conditionally appended
      CountryCode: isEdit && b2bCompany ? b2bCompany.CountryCode : '',
      PhoneNumber: isEdit && b2bCompany ? b2bCompany.PhoneNumber : '',
      Gender: '',
      BirthDate: isEdit && b2bCompany ? b2bCompany.BirthDate : '',
      ProfileImage: '',
      CompanyTags: '',
      userId: isEdit && b2bCompany ? b2bCompany.userId : ''
    },
    validationSchema: changePassword ? ChangePasswordSchema : NewUserSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        let bodyFormData = new FormData();

        if (changePassword) {
          // Only append userId, CompanyId, Password, and ConfirmPassword when changePassword is true
          if (values.userId) {
            bodyFormData.append('Id', values.userId);
          }
          if (isEdit) {
            bodyFormData.append('CompanyId', b2bCompany?.CompanyId as any);
          }
          if (values.Password) {
            bodyFormData.append('Password', values.Password);
          }
          if (values.ConfirmPassword) {
            bodyFormData.append('ConfirmPassword', values.ConfirmPassword);
          }

          await dispatch(updatePasswordEmployeeB2b(bodyFormData));
        } else {
          // Append all other fields for regular create/update operations
          if (!isEdit && values.ProfileImage) {
            bodyFormData.append('ProfileImage', values.ProfileImage);
          }
          if (values.FirstName) {
            bodyFormData.append('FirstName', values.FirstName);
          }
          if (values.LastName) {
            bodyFormData.append('LastName', values.LastName);
          }
          if (values.UserName) {
            bodyFormData.append('UserName', values.UserName);
          }
          if (values.Email) {
            bodyFormData.append('Email', values.Email);
          }
          if (isEdit || (values.Password && values.ConfirmPassword)) {
            if (values.Password) {
              bodyFormData.append('Password', values.Password);
            }
            if (values.ConfirmPassword) {
              bodyFormData.append('ConfirmPassword', values.ConfirmPassword);
            }
          }
          if (!isEdit) {
            if (values.BirthDate) {
              bodyFormData.append('BirthDate', values.BirthDate);
            }
            if (values.CountryCode) {
              bodyFormData.append('CountryCode', values.CountryCode);
            }
            if (values.Gender) {
              bodyFormData.append('Gender', values.Gender);
            }
          }
          if (values.PhoneNumber) {
            bodyFormData.append('PhoneNumber', values.PhoneNumber);
          }
          if (!isEdit) {
            bodyFormData.append('CompanyId', companyId);
          } else {
            bodyFormData.append('CompanyId', b2bCompany?.CompanyId as any);
          }
          // Append userId for edit
          if (isEdit && values.userId) {
            bodyFormData.append('Id', values.userId);
          }

          // Dispatch the appropriate action based on whether it's an edit or create operation
          if (isEdit) {
            await dispatch(UpdateEmployeeB2b(bodyFormData));
          } else {
            await dispatch(createEmployeeB2b(bodyFormData));
          }
        }

        // Reset the form and stop the submission process
        resetForm();
        setSubmitting(false);
        setChangePassword(false);
      } catch (error: any) {
        // Log the error and update the form state with error details
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } =
    formik;

  const theme = useTheme();

  const useStyles = makeStyles({
    root: {
      '& fieldset': {
        background:
          formik.isValid &&
          (theme.palette.mode === 'dark'
            ? `${theme.palette.error.darker} !important`
            : `${theme.palette.error.lighter} !important`)
      }
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('ProfileImage', {
          ...file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFieldValue]
  );
  const classes = useStyles();
  const style = {
    background:
      theme.palette.mode === 'dark'
        ? `${theme.palette.error.darker} !important`
        : `${theme.palette.error.lighter} !important`
  };

  const isDisabled = !formik.isValid || formik.isSubmitting || !formik.dirty;
  const DisbalePasswordButtn = !formik.isValid || formik.isSubmitting || !formik.dirty;

  return (
    <>
      {changePassword === true ? (
        <>
          <FormHeader
            setDisplay={setDisplay}
            Heading={changePassword && translate('Update Password')}
          />
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                type="password"
                label="Password"
                {...formik.getFieldProps('Password')}
                error={Boolean(formik.touched.Password && formik.errors.Password)}
                helperText={formik.touched.Password && formik.errors.Password}
              />

              <TextField
                fullWidth
                type="password"
                label="Confirm Password"
                {...formik.getFieldProps('ConfirmPassword')}
                error={Boolean(formik.touched.ConfirmPassword && formik.errors.ConfirmPassword)}
                helperText={formik.touched.ConfirmPassword && formik.errors.ConfirmPassword}
              />
            </Stack>
            <Box sx={{ mt: 4 }}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
                disabled={DisbalePasswordButtn}
              >
                Update Password
              </LoadingButton>
            </Box>
          </form>
        </>
      ) : (
        <>
          {isEdit && !isSubmitting && isLoading ? (
            <Stack sx={{ justifyContent: 'center', alignItems: 'center', pt: '12px' }}>
              <CircularProgress color="secondary" />
            </Stack>
          ) : (
            <FormikProvider value={formik}>
              <FormHeader
                setDisplay={setDisplay}
                Heading={!isEdit ? translate('Create New Employee') : translate('Edit Employess')}
              />
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', md: 'row' },
                        mt: 3
                      }}
                    >
                      {!isEdit && (
                        <>
                          <Box sx={{ py: 2, px: 3, mx: { xs: 'auto', md: 0 } }}>
                            <Box>
                              <UploadAvatar
                                mainText={translate('profile picture')}
                                accept="image/*"
                                file={values.ProfileImage}
                                maxSize={3145728}
                                onDrop={handleDrop}
                                error={Boolean(touched.ProfileImage && errors.ProfileImage)}
                                caption={
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      mt: 2,
                                      mx: 'auto',
                                      display: 'block',
                                      textAlign: 'center',
                                      color: 'text.secondary'
                                    }}
                                  >
                                    {translate('allowed formats')}
                                    <br />
                                    *.jpeg, *.jpg, *.png, *.gif
                                    <br /> {translate('maximum image size')} {fData(3145728)}
                                  </Typography>
                                }
                              />
                              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                                {touched.ProfileImage && errors.ProfileImage}
                              </FormHelperText>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>

                  {/* Name Fields */}
                  <Grid item xs={12} md={12}>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="First Name"
                          {...getFieldProps('FirstName')}
                          error={Boolean(touched.FirstName && errors.FirstName)}
                          helperText={touched.FirstName && errors.FirstName}
                        />

                        <TextField
                          fullWidth
                          label="Last Name"
                          {...getFieldProps('LastName')}
                          error={Boolean(touched.LastName && errors.LastName)}
                          helperText={touched.LastName && errors.LastName}
                        />
                      </Stack>

                      <TextField
                        fullWidth
                        label="User Name"
                        {...getFieldProps('UserName')}
                        error={Boolean(touched.UserName && errors.UserName)}
                        helperText={touched.UserName && errors.UserName}
                      />

                      <TextField
                        fullWidth
                        label="Email"
                        {...getFieldProps('Email')}
                        error={Boolean(touched.Email && errors.Email)}
                        helperText={touched.Email && errors.Email}
                      />

                      {!isEdit && (
                        <>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <TextField
                              fullWidth
                              type="password"
                              label="Password"
                              {...getFieldProps('Password')}
                              error={Boolean(touched.Password && errors.Password)}
                              helperText={touched.Password && errors.Password}
                            />

                            <TextField
                              fullWidth
                              type="password"
                              label="Confirm Password"
                              {...getFieldProps('ConfirmPassword')}
                              error={Boolean(touched.ConfirmPassword && errors.ConfirmPassword)}
                              helperText={touched.ConfirmPassword && errors.ConfirmPassword}
                            />
                          </Stack>
                        </>
                      )}

                      {/* Contact Fields */}
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Phone Number"
                          {...getFieldProps('PhoneNumber')}
                          error={Boolean(touched.PhoneNumber && errors.PhoneNumber)}
                          helperText={touched.PhoneNumber && errors.PhoneNumber}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Allow only numeric input and limit to 10 characters
                            if (/^[0-9]*$/.test(value) && value.length <= 10) {
                              formik.setFieldValue('PhoneNumber', value);
                            }
                          }}
                        />

                        {isEdit ? (
                          ''
                        ) : (
                          <>
                            <TextField
                              fullWidth
                              label="Country Code"
                              {...getFieldProps('CountryCode')}
                              error={Boolean(touched.CountryCode && errors.CountryCode)}
                              helperText={touched.CountryCode && errors.CountryCode}
                            />
                          </>
                        )}
                      </Stack>

                      {/* Gender & Birth Date Fields */}
                      {isEdit ? (
                        ''
                      ) : (
                        <>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                            <FormControl sx={{ mt: '8px' }} fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {translate('gender')}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={translate('gender')}
                                //  MenuProps = { MenuProps }
                                value={values.Gender}
                                onChange={(e) => formik.setFieldValue('Gender', e.target.value)}
                              >
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={0}>Female</MenuItem>
                              </Select>
                            </FormControl>

                            <TextField
                              fullWidth
                              label="Birth Date"
                              type="date"
                              inputProps={{ max: today }}
                              {...getFieldProps('BirthDate')}
                              error={Boolean(touched.BirthDate && errors.BirthDate)}
                              helperText={touched.BirthDate && errors.BirthDate}
                            />
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Grid>
                </Grid>

                {isEdit && (
                  <>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <Button onClick={() => setChangePassword(!changePassword)}>
                        Change Password
                      </Button>
                    </Box>
                  </>
                )}

                <Box sx={{ mt: 4 }}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isEdit && isDisabled}
                  >
                    {isEdit ? 'Save Changes' : 'Add Employee'}
                  </LoadingButton>
                </Box>
              </Form>
            </FormikProvider>
          )}
        </>
      )}
    </>
  );
}
