import { MouseEvent, useState } from 'react';
import { saveAs } from 'file-saver';
import { Icon } from '@iconify/react';
import fileFill from '@iconify/icons-eva/file-fill';
import deleteIcon from '@iconify/icons-eva/trash-outline';
import eyeOutline from '@iconify/icons-eva/eye-outline';

import arrowCircleDownOutline from '@iconify/icons-eva/arrow-circle-down-outline';
// utils
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  List,
  Box,
  Stack,
  Typography,
  IconButton
} from '@mui/material';
//
import { MIconButton } from './../@material-extend';
import { baseURL } from 'utils/axios';

function getFileExtension(filename: string) {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
}
function getFilesssExtension(filename: string): string | undefined {
  const ext = /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
  return ext ? ext[0] : undefined; // Return the first match as a string
}

function isSpecialFileType(filename: string): boolean {
  const fileExtension = getFilesssExtension(filename);
  return fileExtension === 'doc' || fileExtension === 'docx' || fileExtension === 'mp4';
}
export async function createDocMp4Url({
  fileName,
  action
}: {
  fileName: string;
  action?: 'download' | 'view';
}) {
  let fileUrl = '';
  if (fileName) {
    const response = await fetch(
      `${baseURL}Files/DownloadFile?fileName=${fileName}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      }
    )
      .then((res) => {
        fileUrl = res.url;
        return res.blob();
      })
      .then((myBlob) => {
        const fileType = getFilesssExtension(fileName);

        // Handle mp4
        if (
          fileType === 'mp4' ||
          fileType === 'mov' ||
          fileType === 'wmv' ||
          fileType === 'avi' ||
          fileType === 'mpg' ||
          fileType === 'mpeg' ||
          fileType === 'mkv'
        ) {
          const url = window.URL.createObjectURL(new Blob([myBlob], { type: 'video/mp4' }));
          if (action === 'view') {
            const videoWindow = window.open('');
            videoWindow?.document.write(`
                  <video controls autoplay>
                    <source src="${url}" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                `);
          } else if (action === 'download') {
            window.open(url);
          }
          return url;
        }

        // Handle doc and docx
        if (fileType === 'doc' || fileType === 'docx' || fileType === 'pdf') {
          const encodedFileUrl = encodeURIComponent(fileUrl);
          const officeUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodedFileUrl}`;

          if (action === 'view') {
            const viewerWindow = window.open(officeUrl, '_blank');

            // Retry mechanism
            viewerWindow?.addEventListener('load', function () {
              if (viewerWindow.document.body.innerHTML === '') {
                setTimeout(() => {
                  viewerWindow.location.reload();
                }, 2000); // Wait 2 seconds before reloading
              }
            });
          } else if (action === 'download') {
            window.open(fileUrl);
          }
          return officeUrl;
        }
        return '';
      });

    if (action === 'download') {
      return fileUrl;
    } else {
      return response;
    }
  }
}
export async function createFileUrl({
  fileName,
  type = 'file',
  action
}: {
  fileName: string;
  type?: 'image' | 'file';
  action?: 'download' | 'view';
}) {
  // delete axios.defaults.headers.common['Content-Type'];
  let fileUrl = '';
  if (fileName) {
    const response = await fetch(
      `${baseURL}Files/DownloadFile?fileName=${fileName}`,
      {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          //'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    )
      .then((res) => {
        fileUrl = res.url;
        return res.blob();
      })
      .then((myBlob) => {
        const fileType = getFileExtension(fileName);
        if (type === 'image') {
          let reader = new FileReader();
          reader.readAsDataURL(myBlob);
          return reader;
        } else {
          const url = window.URL.createObjectURL(
            new Blob([myBlob], { type: `application/${fileType}` })
          );
          if (
            action === 'view' &&
            fileType &&
            fileType?.length > 0 &&
            (fileType[0] === 'jpeg' ||
              fileType[0] === 'png' ||
              fileType[0] === 'jpg' ||
              fileType[0] === 'svg' ||
              fileType[0] === 'gif' ||
              fileType[0] === 'webp' ||
              fileType[0] === 'bmp' ||
              fileType[0] === 'JPG' ||
              fileType[0] === 'JPEG' ||
              fileType[0] === 'PNG')
          ) {
            let reader = new FileReader();
            reader.readAsDataURL(myBlob);
            reader.onload = function () {
              var image = new Image();
              // @ts-ignore
              image.src = reader.result;
              var w = window.open('');
              w?.document.write(image.outerHTML);
            };
          } else if (action === 'view') {
            window.open(url);
          }
          return url;
        }
      });
    if (action === 'download') {
      return fileUrl;
    } else {
      return response;
    }
  }
}

type FilePreviewProps = {
  src: string;
  isDelete?: boolean;
  handleDelete?: (e: MouseEvent<HTMLButtonElement>, file: any) => void;
};

export default function FilePreview({ src, isDelete = false, handleDelete }: FilePreviewProps) {
  async function handleFileAction(fileInfo: any) {
    if (isSpecialFileType(fileInfo.fileName)) {
      await createDocMp4Url(fileInfo);
    }
  }

  async function createFile(fileInfo: any, event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation(); // Stop the event from propagating
    await handleFileAction(fileInfo);
    const fileData = await createFileUrl(fileInfo);
    if (fileInfo?.action === 'download' && fileData) {
      // @ts-ignore
      window.location.href = fileData;
    }
  }
  return (
    <List
      sx={{
        my: 1,
        py: 0.75,
        px: 1,
        borderRadius: 1,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <ListItem>
        <ListItemIcon>
          <Icon icon={fileFill} width={28} height={28} />
        </ListItemIcon>

        <ListItemText
          sx={{ fontSize: 6, wordBreak: 'normal' }}
          primary={src.substring(0, 10) + '..' + src.split('.')[1]}
          primaryTypographyProps={{ variant: 'subtitle2' }}
          secondaryTypographyProps={{ variant: 'caption' }}
        />
      </ListItem>

      <ListItem>
        <ListItemSecondaryAction>
          <Tooltip title="معاينة">
            <MIconButton
              sx={{ mx: 1 }}
              edge="end"
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation(); // Stop propagation for the view button
                createFile(
                  {
                    fileName: src,
                    type: 'file',
                    action: 'view'
                  },
                  e
                ); // Pass event to the createFile function
              }}
            >
              <Icon icon={eyeOutline} />
            </MIconButton>
          </Tooltip>

          <Tooltip title="تحميل">
            <MIconButton
              sx={{ mx: 1 }}
              edge="end"
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                createFile(
                  {
                    fileName: src,
                    type: 'file',
                    action: 'download'
                  },
                  e
                );
              }}
            >
              <Icon icon={arrowCircleDownOutline} />
            </MIconButton>
          </Tooltip>
          {isDelete && (
            <Tooltip title="حذف">
              <MIconButton
                sx={{ mx: 1 }}
                edge="end"
                size="small"
                onClick={handleDelete ? (e) => handleDelete(e, src) : () => {}}
              >
                <Icon icon={deleteIcon} />
              </MIconButton>
            </Tooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
