import { Button, Typography, DialogContent, IconButton, Box } from '@mui/material';

import { cancelSuspendLawyer, updateLawyerStatus } from '../../../redux/slices/lawyer';
import Close from '@mui/icons-material/Close';
import 'moment/locale/ar';
import { DialogAnimate } from 'components/animate';
import { useDispatch } from 'react-redux';
import {  B2bCompanyCustomersUpdate } from '../../../@types/b2b';
import { deleteB2b, updateB2bCompanyCustomer } from 'redux/slices/b2b';
import { useFormik } from 'formik';

type ChangeCustomerStatusModalProps = {
  type: 'delete'|'' ;
  open: boolean;
  setOpen: (open: boolean) => void;
  b2b: B2bCompanyCustomersUpdate;
  companyId?:string
};
export default function ChangeCustomerStatusModal({ type, open, setOpen, b2b,companyId }: ChangeCustomerStatusModalProps) {
  const dispatch = useDispatch();
  return (
    <DialogAnimate
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      PaperProps={{
        sx: { width: '50%', maxWidth: '90%' }
      }}
    >
      <DialogContent>
        <IconButton
           onClick={() => {
            setOpen(false);
          }}
          aria-label="delete"
          size="small"
        >
          <Close sx={{ color: 'text.primary' }} />
        </IconButton>
        <Box>
          { (
            type === 'delete' && (
              <>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  هل انت متاكد من حذف الموظف  {b2b?.FirstName} من الشركة
                </Typography>{' '}
              </>
            )
          )}
        </Box>
        <Box sx={{ p: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {' '}
          <Button
            onClick={() => {
              let bodyFormData = new FormData();
              // @ts-ignore
              bodyFormData.append('UserId', b2b.UserId);
              bodyFormData.append('PhoneNumber', b2b.PhoneNumber);
              bodyFormData.append('CompanyTags', '');
              dispatch(updateB2bCompanyCustomer(bodyFormData,companyId))
              setOpen(false);
            }}
            variant="contained"
            color="success"
            sx={{
              color: '#229A16',
              backgroundColor: 'rgba(84, 214, 44, 0.16)',
              '&:hover': {
                color: 'white'
              }
            }}
          >
            تأكيد{' '}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="error"
            variant="contained"
            sx={{
              color: '#B72136',
              backgroundColor: 'rgba(255, 72, 66, 0.16)',
              mx: '20px',
              '&:hover': {
                color: 'white'
              }
            }}
          >
            {' '}
            الغاء{' '}
          </Button>
        </Box>
       
      </DialogContent>
    </DialogAnimate>
  );
}
