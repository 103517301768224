import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { Class, ClassesState } from '../../@types/classes';

// ----------------------------------------------------------------------

const initialState: ClassesState = {
  isLoading: false,
  error: false,
  classList: [],
  classDetails: {
    id: 0,
    name: '',
    description: '',
  },
  addClassStatus: {},
  editClassStatus: {},
  deleteClassStatus: {}
};

const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getClassesListSuccess(state, action) {
      state.isLoading = false;
      state.classList = action.payload;
    },
    getClassSuccess(state, action) {
      state.isLoading = false;
      state.classDetails = action.payload;
    },
    addClassStatus(state, action) {
      state.isLoading = false;
      state.addClassStatus = action.payload;
    },
    editClassStatus(state, action) {
      state.isLoading = false;
      state.editClassStatus = action.payload;
    },
    deleteClassStatus(state, action) {
      state.isLoading = false;
      state.deleteClassStatus = action.payload;
    }
  }
});

export default slice.reducer;

//-------------------------------------------------------------------------------------

export function getClassesList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/LawyerClass/GetAllLawyerClassesAsync');
      dispatch(slice.actions.getClassesListSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClassDetails(classId: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/LawyerClass/GetLawyerClassByIdAsync?Id=${classId}`);
   
      dispatch(slice.actions.getClassSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setClassDetails(data: any) {
  return dispatch(slice.actions.getClassSuccess(data));
}

export function addClass(data:Class ) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/LawyerClass/CreateLawyerClassAsync`, data);
      if(response?.data?.status === false)
      {
        
        dispatch(slice.actions.addClassStatus({ status: 'error', error: response?.data })); 
      }
      else
      dispatch(slice.actions.addClassStatus({ status: 'success', ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.addClassStatus({ status: 'error', error: error }));
    }
  };
}

export function setAddClass(data: any) {
  return dispatch(slice.actions.addClassStatus(data));
}

export function editClass(data: Class) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/LawyerClass/UpdateLawyerClassAsync`, data);
      if(response?.data?.status === false)
      {
        
        dispatch(slice.actions.editClassStatus({ status: 'error', error: response?.data })); 
      }
      else
      dispatch(slice.actions.editClassStatus({ status: 'success', ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.editClassStatus({ status: 'error', error: error }));
    }
  };
}

export function setEditClass(data: any) {
  return dispatch(slice.actions.editClassStatus(data));
}


//Not Added yet

// export function deleteClass(classId: any, lastModifiedBy: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.delete(`/Package/Delete`,
//       { 
//         data:{
//         "id":classId,
//         "isActive":false,
//         "lastModifiedBy":lastModifiedBy
//       }
//      });
//       dispatch(slice.actions.deleteClassStatus({ status: 'success', ...response?.data?.result }));
//     } catch (error) {
//       Sentry.captureException(error);
//       dispatch(slice.actions.hasError(error));
//       dispatch(slice.actions.deleteClassStatus({ status: 'error', error: error }));
//     }
//   };
// }

// export function setDeleteClass(data: any) {
//   return dispatch(slice.actions.deleteClassStatus(data));
// }
 