import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { Box, Input, Slide, Button, InputAdornment, ClickAwayListener } from '@mui/material';
import useLocales from 'hooks/useLocales';

export default function Searchbar() {

const { translate } = useLocales();
  return (

           
          <div>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder={translate('search')}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold', bgcolor:'background.mainColor',width:{ xs:'100%',md:'150%' },borderRadius:1,px:2 }}
            />
           
    </div>
  );
}
