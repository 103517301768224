import { Attachment, Cancel, Send } from '@mui/icons-material';
import { alpha, styled } from '@mui/material';
import { CircularProgress, IconButton, Input, TextField } from '@mui/material';
import { Box, Slide, Stack, Typography, useTheme } from '@mui/material';
import FilePreview from 'components/file/FilePreview';
import { NewFileViewer } from 'components/file/NewFileViewer';
import Scrollbar from 'components/Scrollbar';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllMessages, sendMessageByAdmin } from 'redux/slices/chat';
import { uploadFilesCall } from 'redux/slices/files';
import { RootState, useDispatch, useSelector } from 'redux/store';
import SimpleBar from 'simplebar-react';
import axiosInstance, { IMAGE_URL } from 'utils/axios';

const SimpleBarStyle = styled(SimpleBar)(
  ({
    theme, // @ts-ignore
    scrollStyles
  }) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
      '&:before': {
        backgroundColor: scrollStyles?.bar?.backgroundColor
          ? scrollStyles?.bar?.backgroundColor
          : alpha(theme.palette.grey[600], 0.48)
      },
      '&.simplebar-visible:before': {
        opacity: 1
      }
    },
    '& .simplebar-track.simplebar-vertical': {
      width: scrollStyles?.track?.width ? scrollStyles?.track?.width : 10,
      backgroundColor: scrollStyles?.track?.backgroundColor
        ? scrollStyles?.track?.backgroundColor
        : 'transparent'
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
      height: 6
    },
    '& .simplebar-mask': {
      zIndex: 'inherit'
    }
  })
);

type ChatBoxProps = {
  openChatBox: boolean;
  closeChatBox: Function;
  width?: string;
  id: any;
  type: number;
  userId: any;
  createdDate: any;
  toUserId: any;
};

// type = 0 => appointment
// type = 1 => MasterRequest
// type = 2 => MasterRequestApproved

const DashboardChatBox = ({
  openChatBox,
  closeChatBox,
  width = '100%',
  id,
  userId,
  type,
  createdDate,
  toUserId
}: ChatBoxProps) => {
  const { user } = useAuth();
  const messagesEndRef = useRef<any>(null);
  const { allMessages, isLoading } = useSelector((state: RootState) => state.chat);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [message, setMessage] = useState('');

  const [sendingMessageLoader, setSendingMessageLoader] = useState(false);
  const [gettingMessageLoader, setGettingMessageLoader] = useState(false);

  const [messagesData, setMessagesData] = useState<any>([]);
  const [messageFile, setMessageFile] = useState<any>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollableNode = messagesEndRef.current.getScrollElement();
      scrollableNode.scrollTop = scrollableNode.scrollHeight;
    }
  };
  async function getMsgList() {
    try {
      setGettingMessageLoader(true);
      let response = await axiosInstance.get(`Chat/GetMessages`, {
        params: {
          ReferenceId: id,
          ReferenceType: type,
          ToUserId: userId,
          createdDate: createdDate,
          Size: 1000,
          FromUserId: toUserId
        }
      });
      setMessagesData([...response.data?.result?.data?.reverse()]);
    } catch (error) {
    } finally {
      setGettingMessageLoader(false);
    }
  }

  useEffect(() => {
    if (openChatBox) {
      getMsgList().then(() => scrollToBottom());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, userId, openChatBox, createdDate]);

  const sendMessageHandler = async () => {
    let messageData = new FormData();

    var fileUrl = null;
    setSendingMessageLoader(true);

    if (messageFile) {
      let filesData = new FormData();
      filesData.append('files', messageFile);
      const { files, STATUS } = await dispatch(uploadFilesCall(filesData));
      if (STATUS) fileUrl = files[0];

      if (type === 0) {
        messageData.append('Attachments', messageFile);
      } else {
        messageData.append('Files', fileUrl);
      }
      setMessageFile(null);
    }
    messageData.append('MasterRequestId', id);
    messageData.append('ToUser', toUserId);
    messageData.append('Message', message?.trim());
    messageData.append('ReferenceId', id);
    messageData.append('ReferenceType', String(type));
    messageData.append('MarkCompleted', String(false));
    // Format the created date
    let createdDate = new Date().toISOString();
    messageData.append('CreatedDate', createdDate);

    let response = await dispatch(sendMessageByAdmin(messageData));
    if (response.status) {
      const newMessage = {
        fromUserId: user?.id,
        message: message,
        createdDate: createdDate,
        files: fileUrl ? [fileUrl] : []
      };
      setMessagesData([...messagesData, newMessage]);
      setMessage('');
    }

    setSendingMessageLoader(false);
    scrollToBottom();
  };

  return (
    <Slide
      direction={theme.direction === 'rtl' ? 'right' : 'left'}
      in={openChatBox}
      unmountOnExit
      mountOnEnter
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: width,
          height: '100%',
          zIndex: 1,
          transition: 'all'
        }}
      >
        <Stack width={'100%'} height={'100%'} justifyContent="center" alignItems="center">
          <Box
            sx={{
              // bgcolor: theme.palette.background.mainColor,
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.customShadows.primary,
              width: '90%',
              height: '95%',
              margin: 'auto',
              border: '2px solid',
              borderRadius: '15px',
              borderColor: theme.palette.primary.main,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            <Stack
              width={'100%'}
              zIndex={1}
              height="9%"
              alignItems={theme.direction === 'rtl' ? 'flex-start' : 'flex-end'}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  closeChatBox();
                }}
              >
                <Cancel sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>

            <Box sx={{ height: '65%' }}>
              <SimpleBarStyle
                ref={messagesEndRef}
                style={{
                  height: '100%'
                }}
              >
                {gettingMessageLoader ? (
                  <Stack alignItems={'center'} justifyContent={'center'}>
                    <CircularProgress size={25} />
                  </Stack>
                ) : (
                  <Stack p={1} gap={1}>
                    {messagesData?.map((data: any, ind: number) => {
                      //   <div key={ind}>
                      //  { data.id === 0 ?

                      return (
                        <Box
                          key={ind}
                          sx={{
                            bgcolor:
                              data?.fromUserId === user?.id
                                ? theme.palette.success.light
                                : theme.palette.error.light,
                            borderRadius: 1,
                            maxWidth: '50%',
                            p: 1,
                            alignSelf:
                              theme.direction === 'rtl'
                                ? data?.fromUserId === user?.id
                                  ? 'flex-start'
                                  : 'flex-end'
                                : data?.fromUserId === user?.id
                                ? 'flex-end'
                                : 'flex-start'
                          }}
                        >
                          <Typography
                            sx={{
                              wordBreak: 'break-word'
                            }}
                            lineHeight={1}
                            color={'#000'}
                            textAlign={theme.direction === 'rtl' ? 'right' : 'left'}
                          >
                            {data.message}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            textAlign={theme.direction === 'rtl' ? 'right' : 'left'}
                          >
                            {new Date(data.createdDate).toLocaleString()}
                          </Typography>

                          {data?.files?.map((file: string, ind: number) => (
                            <Box key={ind}>
                              <NewFileViewer fileName={file} />
                            </Box>
                          ))}
                        </Box>
                      );
                    })}
                    {/* <div ref={messagesEndRef} /> */}
                  </Stack>
                )}
              </SimpleBarStyle>
            </Box>

            <Box
              sx={{
                border: '1px solid',
                borderColor: theme.palette.primary.main,
                margin: 0.5,
                borderRadius: '12px',
                overflow: 'hidden',
                zIndex: 1,
                maxHeight: '25%',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.background.default,
                px: 1
              }}
            >
              {sendingMessageLoader ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  onClick={sendMessageHandler}
                  disabled={!messageFile && message.trim().length === 0}
                >
                  <Send />
                </IconButton>
              )}

              {messageFile ? (
                <Stack
                  sx={{
                    padding: '2px',
                    borderRadius: '12px',
                    width: '75%',
                    marginY: '10px'
                  }}
                  bgcolor={theme.palette.grey[300]}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  flexDirection={'row'}
                >
                  <IconButton size="small" onClick={() => setMessageFile(null)}>
                    <Cancel scale={1} />
                  </IconButton>
                  <Typography
                    sx={{
                      display: 'block',
                      maxWidth: '90%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      paddingX: '5px'
                    }}
                  >
                    {messageFile?.name}
                  </Typography>
                </Stack>
              ) : (
                <TextField
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none' // Removes the border
                      },
                      '&:hover fieldset': {
                        border: 'none' // Ensures no border on hover
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none' // Ensures no border on focus
                      }
                    },
                    flex: 1,
                    maxHeight: '100%',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '10px !important' /* Adjust the width of the scrollbar */
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'transparent' /* Color of the scrollbar track */
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: theme.palette.grey[500] /* Color of the scrollbar thumb */,
                      borderRadius: '10px' /* Roundness of the scrollbar thumb */
                    }
                  }}
                  // maxRows={2}
                  type="text"
                />
              )}
              <IconButton disabled={message.trim().length > 0}>
                <label htmlFor="file">
                  <Attachment />
                </label>
                <input
                  onChange={(e: any) => setMessageFile(e.target.files[0])}
                  hidden
                  id="file"
                  type="file"
                />
              </IconButton>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Slide>
  );
};

export default DashboardChatBox;
