/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import FormHeader from '../FormHeader';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { editQuestion, getQuestionDetails, resetQuestionDetails, setQuestionDetails, setUpdateMusherStatus } from 'redux/slices/musher';
import useLocales from 'hooks/useLocales';

type CreateEditQuestionsProps = {
  isEdit: boolean;
  packageId: string;
  setDisplay: Function;
};

export default function CreateEditQuestions({ isEdit, packageId, setDisplay }: CreateEditQuestionsProps) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, questionData, editQestionStatus } = useSelector(
    (state: RootState) => state.musher
  );

  const [isSubmitting, setSubmitting] = useState(false);
  const { translate } = useLocales();
  

  useEffect(() => {
    dispatch(
      getQuestionDetails({
        id: packageId,
      })
    );
  }, [dispatch, packageId]);


  useEffect(() => {
    if (editQestionStatus?.status === 'Success' && isSubmitting) {

      setSubmitting(false);
      enqueueSnackbar('تم تعديل السؤال بنجاح', {
        variant: 'success',
        onClose: () => {
          dispatch(resetQuestionDetails({}));
        }

      });
      setDisplay(false)

    } else if (editQestionStatus?.status === 'error') {
      setSubmitting(false);
      enqueueSnackbar("خطأ", {
        variant: 'error',
        onClose: () => {
          dispatch(resetQuestionDetails({}));
        }
      });
      setDisplay(false)
    }
  }, [editQestionStatus, enqueueSnackbar, dispatch, isEdit, isSubmitting]);


  const NewUserSchema = Yup.object().shape({
    answer: Yup.string().required('الأجابة مطلوبة'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      answer: questionData?.data?.answer || '',
    },
    validationSchema: NewUserSchema,
    validateOnMount: true,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {

        if (isEdit) {
          dispatch(
            editQuestion({
              id: packageId,
              correctAnswer: values?.answer,
            })
          );

        } else {
          dispatch(editQuestion({
            id: packageId,
            correctAnswer: values?.answer,
          }));
        }
        // resetForm();
        setSubmitting(true);

      } catch (error: any) {
        setErrors(error);
      }
    }
  });
  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      '& fieldset': {
        background: formik.isValid && (theme.palette.mode === 'dark'
          ? `${theme.palette.error.darker} !important` : `${theme.palette.error.lighter} !important`)
      },

    },

  })
  const classes = useStyles();

  return (
    <>
      {isEdit && !isSubmitting && isLoading ?
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', pt: '12px' }} >
          <CircularProgress color="secondary" />
        </Stack> :
        <FormikProvider value={formik}>
          <FormHeader setDisplay={setDisplay} Heading={!isEdit ? translate('create a new package') : 'تعديل الأجابة'} />
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            {' '}
            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>

                <Box>
                  <Typography sx={{ color: 'text.secondary' }}>الأجابة</Typography>

                  <TextField multiline
                    required
                    label={errors.answer}
                    rows={4}
                    onChange={(newValue) => setFieldValue('answer', newValue.target.value)}
                    error={Boolean(touched.answer && errors.answer)}
                    helperText={touched.answer && errors.answer}
                    fullWidth className={classes.root} defaultValue={questionData?.data?.answer} />

                </Box>

              </Grid>
              {questionData?.data?.correctAnswer ?
                <Grid item xs={12} md={12}>

                  <Box>
                    <Typography sx={{ color: 'text.secondary' }}>الأجابة بعد التعديل</Typography>

                    <TextField multiline
                      rows={4}
                      disabled
                      fullWidth className={classes.root} defaultValue={questionData?.data?.correctAnswer} />

                  </Box>

                </Grid>
                :
                null
              }

            </Grid>
            <Grid container spacing={3}>

            </Grid>
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'start' }}>
              <LoadingButton disabled={!formik.isValid} type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? translate('Create a package') : 'حفظ التعديلات'}
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>}
    </>
  );
}
