// material
import { TableRow, TableCell, TableHead, Checkbox ,TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

type B2bListHeadProps = {
  rowCount: number;
  headLabel: any[];
  numSelected: number;
  hasSort?: boolean;
  orderBy?: any;
  order?: any;
  handleRequestSort?: (isAsc: any, property: any) => void;

};

export default function B2bListEmployeesHead({ rowCount, headLabel, hasSort,order,
  orderBy,handleRequestSort }: B2bListHeadProps) {
  
  const { translate } = useLocales();
    return (
    <TableHead>
      <TableRow sx={{ width:'10px' }}>

        {headLabel.map((headCell) => (
          <TableCell
          sx={{ padding:'10px 1px' }}
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            //sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
            <TableSortLabel
              // hideSortIcon
              // active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={(e) => handleRequestSort &&
                handleRequestSort(orderBy === headCell.id && order === 'asc', headCell.id)}            >
          
            {translate(headCell.label)}

            
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            ) : (
              translate(headCell.label)
            )}
             
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
