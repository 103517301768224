import { useEffect } from 'react';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import Page from '../../../components/Page';
import B2bCompanyForm from '../../../components/_dashboard/b2b/B2bCompanyForm';
import { getB2bCompany } from 'redux/slices/b2b';
import {
  B2bCompanies,
  B2bCompanyCustomersUpdate,
  B2bUpdateCompanyEmployees
} from '../../../@types/b2b';
import B2bCompanyCustomerForm from 'components/_dashboard/b2b/B2bCompanyCustomerForm';
import B2bEmployeesCreatingForm from 'components/_dashboard/b2b/B2bEmployeesCreatingForm';

type B2bCompanyCustomersCreateProps = {
  setDisplay: Function;
  b2b: B2bCompanyCustomersUpdate;
  companyId: string;
  openCompanyCustomersForm: boolean;
  isAdd: any;
};
export default function B2bEmployeesCreate({
  setDisplay,
  b2b,
  companyId,
  openCompanyCustomersForm,
  isAdd
}: B2bCompanyCustomersCreateProps) {
  const dispatch = useDispatch();
  const isEdit = isAdd === 'add' ? false : true;

  const { b2bCompany, isLoading } = useSelector((state: RootState) => state.b2b);

  // useEffect(() => {

  //   if (isEdit)
  //     dispatch(getB2bCompany(name));
  // }, [dispatch, name, isEdit]);

  return (
    <Page>
      <B2bEmployeesCreatingForm
        isEdit={isEdit}
        b2bCompany={b2b}
        setDisplay={setDisplay}
        companyId={companyId}
      />
    </Page>
  );
}
