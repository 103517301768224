import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { CSSProperties, styled } from '@mui/system';
import LoadingTable from 'components/LoadingTable';
import Scrollbar from 'components/Scrollbar';
import { useEffect } from 'react';
import { getUpCommingAppointmentList } from 'redux/slices/appointment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import EmptyTable from 'components/EmptyTable';
import moment from 'moment';
import { AccessTime } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';


const TableLink:any = styled(Link)(({ theme }:{ theme:any }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
})); 


export default function ComingAppoinments({ style }: { style?: CSSProperties }) {
  const dispatch = useDispatch();
  
  const appointmentData = useSelector(
    (state: RootState) => state.appointment.UpcommingAppointmentList
  );


  const isLoading = useSelector((state: RootState) => state.appointment.isLoading);

  useEffect(() => {
    dispatch(getUpCommingAppointmentList());
  }, [dispatch]);

  return (
    <>
      <Scrollbar
        sx={{
          width: '100%',
          maxWidth: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '300px',
          ...style
        }}
        // @ts-ignore
        scrollStyles={{
          bar: {
            backgroundColor: '#d49e24'
          },
          track: {
            backgroundColor: '#F4F6F8',
            width: 8
          }
        }}
      >
        <TableContainer sx={{ minWidth: '100%' }}>
          <Table>
            {isLoading && appointmentData.length === 0 ? (
              <LoadingTable />
            ) : (
              <TableBody>
                {appointmentData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <EmptyTable message="لا يوجد مواعيد فى الوقت الحالى" />
                    </TableCell>
                  </TableRow>
                ) : (
                  appointmentData?.map((data: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: '12px',
                            color: '#637381'
                          }}
                        >
                          <TableLink to={`${PATH_DASHBOARD.appointments.admin}/${data?.appointmentId}/details/0`}>
                          {data?.lawyerName}
                          </TableLink>
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: '12px'
                          }}
                        >
                          {data?.categoryName}
                        </TableCell>

                        <TableCell
                          dir="ltr"
                          sx={{
                            fontSize: '12px'
                          }}
                        >
                          {moment(data?.appointmentDate.split('T')[0])
                            .locale('en')
                            .format(`DD-MM-YYYY `)}

                          <AccessTime sx={{ mb: -0.3 }} fontSize="inherit" color="secondary" />

                          {moment(data?.appointmentTime, 'HH:mm').format('h:mm A')}
                        </TableCell>

                        <TableCell
                          dir="ltr"
                          sx={{
                            fontSize: '12px'
                          }}
                        >
                          {
                          moment.duration(
                            moment(
                              data?.appointmentDate.split('T')[0] + ' ' + data?.appointmentTime
                            ).diff(moment())
                          ).asSeconds() > 0 
                            ? moment
                                .duration(
                                  moment(
                                    data?.appointmentDate.split('T')[0] +
                                      ' ' +
                                      data?.appointmentTime
                                  ).diff(moment())
                                )
                                .humanize() + ' remaining'
                            : 'Time Passed'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
}
