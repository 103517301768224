import { Button, Typography, DialogContent, IconButton, Box } from '@mui/material';

import { cancelSuspendLawyer, updateLawyerStatus } from '../../../redux/slices/lawyer';
import Close from '@mui/icons-material/Close';
import 'moment/locale/ar';
import { DialogAnimate } from 'components/animate';
import { useDispatch, useSelector } from 'react-redux';
import { IB2bCompaniesList, B2bCompanies } from '../../../@types/b2b';
import { deleteB2b, DeleteEmployeeB2b, setDeleteEmployee } from 'redux/slices/b2b';
import { useEffect } from 'react';
import { RootState } from 'redux/store';
import { useSnackbar } from 'notistack';

type ChangeStatusModalProps = {
  type: 'Empdelete' | '';
  open: boolean;
  setOpen: (open: boolean) => void;
  b2b: any;
};
export default function DeleteCompanyEmployeeModal({
  type,
  open,
  setOpen,
  b2b
}: ChangeStatusModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { error, isDeleteEmployee, deleteEmployee, showSuccessMessage, showError } = useSelector(
    (state: RootState) => state.b2b
  );

  useEffect(() => {
    if (isDeleteEmployee === true) {
      enqueueSnackbar(showSuccessMessage, { variant: 'success' });
    } else if (isDeleteEmployee === false) {
      enqueueSnackbar(showError, { variant: 'success' });
    }
    setDeleteEmployee(null);
  }, [isDeleteEmployee, dispatch, enqueueSnackbar, showSuccessMessage, showError]);

  return (
    <DialogAnimate
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      PaperProps={{
        sx: { width: '50%', maxWidth: '90%' }
      }}
    >
      <DialogContent>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          aria-label="delete"
          size="small"
        >
          <Close sx={{ color: 'text.primary' }} />
        </IconButton>
        <Box>
          {type === 'Empdelete' && (
            <>
              <Typography variant="h5" sx={{ textAlign: 'center' }}>
                هل أنت متأكد من حذف الموظف؟{b2b?.shortName}
              </Typography>{' '}
            </>
          )}
        </Box>
        <Box sx={{ p: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {' '}
          <Button
            onClick={() => {
              dispatch(DeleteEmployeeB2b(b2b?.Id)); // Pass the Id directly
              setOpen(false);
            }}
            variant="contained"
            color="success"
            sx={{
              color: '#229A16',
              backgroundColor: 'rgba(84, 214, 44, 0.16)',
              '&:hover': {
                color: 'white'
              }
            }}
          >
            تأكيد{' '}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="error"
            variant="contained"
            sx={{
              color: '#B72136',
              backgroundColor: 'rgba(255, 72, 66, 0.16)',
              mx: '20px',
              '&:hover': {
                color: 'white'
              }
            }}
          >
            {' '}
            الغاء{' '}
          </Button>
        </Box>
      </DialogContent>
    </DialogAnimate>
  );
}
