import React from 'react'
import { Stack,Typography,IconButton,Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { reset } from 'redux/slices/b2b'


const FormHeader = (
    { Heading,setDisplay }:{ Heading:string , setDisplay:Function }
) => {
  return (
    <Stack 
    sx={{ position:'sticky', top:0 , zIndex:999,bgcolor:'background.paper',height:50 }}  
    direction= { 'row' } mb={4} 
    justifyContent='space-between' 
    alignItems='end' >
    
    <Typography variant='h4' gutterBottom>{Heading}</Typography>
 
    <IconButton  onClick={()=>{
      setDisplay(false) 
      }}>
        
        <Close sx={{ color:'text.disabled', }}/>  
     </IconButton>   
   
   </Stack>
  
  )
}  

export default FormHeader