import * as Sentry from '@sentry/react';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from '../../utils/axios';
import { PromotionsState } from '../../@types/promotions';
import { createFileUrl } from 'components/file/FilePreview';

// ----------------------------------------------------------------------

const initialState: PromotionsState = {
  isLoading: false,
  error: false,
  promotionsList: { data: [], count: 0, size: 0, page: 0 },
  promotionDetails: {
    codeId: 0,
    codeName: '',
    createdDate: '',
    discount: '',
    limit: '',
    limitRemaining: '',
    maxDiscountAmountLimit: '',
    status: false,
    validFrom: '',
    validTo: '',
    packages: undefined,
    mobileRequestCategries: undefined
  },
  addPromotionStatus: {},
  editPromotionStatus: {},
  deletePromotionStatus: {},
  activePromotionStatus: {}
};

const slice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPromotionsListSuccess(state, action) {
      state.isLoading = false;
      state.promotionsList = action.payload;
    },
    getPromotionSuccess(state, action) {
      state.isLoading = false;
      state.promotionDetails = action.payload;
    },
    addPromotionStatus(state, action) {
      state.isLoading = false;
      state.addPromotionStatus = action.payload;
    },
    editPromotionStatus(state, action) {
      state.isLoading = false;
      state.editPromotionStatus = action.payload;
    },
    deletePromotionStatus(state, action) {
      state.isLoading = false;
      state.deletePromotionStatus = action.payload;
    },
    activePromotionStatus(state, action) {
      state.isLoading = false;
      state.activePromotionStatus = action.payload;
    }
  }
});

export default slice.reducer;

//-------------------------------------------------------------------------------------

export function getPromotionsList(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/PromotionCode', { params: { ...params } });
      dispatch(slice.actions.getPromotionsListSuccess(response?.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromotionDetails(promotionId: string | number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/PromotionCode/GetPromotionCodeById?id=${promotionId}`);

      dispatch(slice.actions.getPromotionSuccess({ ...response?.data?.result, image: '' }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setPromotionDetails(data: any) {
  return dispatch(slice.actions.getPromotionSuccess(data));
}

export function addPromotion(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/PromotionCode`, data);
      if(response?.data?.status === false)
      {
       
      dispatch(slice.actions.addPromotionStatus({ error: response?.data, status: 'error' }));

      } 
      else 
      dispatch(slice.actions.addPromotionStatus({ status: 'success', ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.addPromotionStatus({ status: 'error', error: error }));
    }
  };
}

export function setAddPromotion(data: any) {
  return dispatch(slice.actions.addPromotionStatus(data));
}

export function editpromotion(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/PromotionCode/UpdatePromotionCode`, data);
      if(response?.data?.status === false)
      {
        
      dispatch(slice.actions.editPromotionStatus({ error: response?.data, status: 'error' }));

      } 
      else
      dispatch(slice.actions.editPromotionStatus({ status: 'success', ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.editPromotionStatus({ status: 'error', error: error }));
    }
  };
}

export function UpdateStatusPromotionCode(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/PromotionCode/UpdateStatusPromotionCode`, data);
      if(response?.data?.status === false)
      {
        
      // dispatch(slice.actions.editPromotionStatus({ error: response?.data, status: 'error' }));
      dispatch(slice.actions.activePromotionStatus({ status: 'error', error: response?.data })); 

      } 
      else
      dispatch(slice.actions.activePromotionStatus({ status: 'success', ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.activePromotionStatus({ status: 'error', error: error }));
    }
  };
}
export function setActivePromotion(data: any) {
  return dispatch(slice.actions.activePromotionStatus(data));
}

export function setEditPromotion(data: any) {
  return dispatch(slice.actions.editPromotionStatus(data));
}

export function deletePromotion(promotionId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/PromotionCode/${promotionId}`);
      if(response?.data?.status === false)
      {
        
        dispatch(slice.actions.deletePromotionStatus({ status: 'error', error: response?.data })); 
      }
      else
      dispatch(slice.actions.deletePromotionStatus({ status: 'success', ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.deletePromotionStatus({ status: 'error', error: error }));
    }
  };
}

export function setDeletePromotion(data: any) {
  return dispatch(slice.actions.deletePromotionStatus(data));
}
