import { Theme } from '@mui/material/styles';
import { boxSizing } from '@mui/system';

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled } 
          }
        },
        input: {
          '&::placeholder': {
            opacity: 1,
             color: theme.palette.text.disabled
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16]
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: { 
        root: {
            '& fieldset': {            // - The <fieldset> inside the Input-root
                borderColor: theme.palette.background.mainColor,
                backgroundColor:theme.palette.background.mainColor,
                // - Set the Input border
                zIndex:0,
            }, 
          
            '.MuiOutlinedInput-input':{

             zIndex:1
          
            },
            '& .MuiInputAdornment-root': {
              zIndex: 2, // Higher z-index for the icon
             
            },
            '& svg': {
              zIndex: 3,
            },
           
        
        },
       
      }
    }
  };
}
