import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z20,
         
        },
        root:{
          backgroundColor:theme.palette.background.mainColor,
          border:'1px solid',
          borderColor: theme.palette.background.mainColor,
          '&:hover':{
          borderColor: theme.palette.text.primary  
          },   
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
          borderColor: theme.palette.text.primary  
          }, 
          borderRadius:'9px'  
        } 
      }
    }
  };
}
