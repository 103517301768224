import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Table(theme: Theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover
            }
          }
        }
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          textAlign:'center',
        },
        head: {
          color: theme.palette.text.tableHeadText,
          backgroundColor: theme.palette.background.tableHead,     
          padding:10,     
          '&:first-of-type': {
           // paddingLeft: theme.spacing(3),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            //boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`
          },
          '&:last-of-type': {
            //paddingRight: theme.spacing(3),
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
           // boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`
          }
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
        },
        body: {
          borderLeft:`solid 1px ${theme.palette.divider}`,
          padding:10,     
          borderBottom: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : `1px solid #F4F6F8`
          // '&:first-of-type': {
          //   //paddingLeft: theme.spacing(3),
          //   border:'none'
          
          // },
          // '&:last-of-type': {
          //   //paddingRight: theme.spacing(3),
          // }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          // borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}
