import * as Sentry from "@sentry/react";
import { find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { PermissionState } from "../../@types/permission";

// @types

// ----------------------------------------------------------------------


const initialState: PermissionState = {
  isLoading: false,
  isAssigning: false, 
  error: false,
  PermissionList:[],
  UserPermissions:[],
  Permission:{},
  createPermissionStatus:{},
  updatePermissionStatus:{},
  deletePermissionStatus:{},
  assignPermissonSuccess:{},
  permissionActions:[],
  deletePermissionsForUserStatus:{},
  modulesList:[],
  actionsList:[]
};





const slice = createSlice({
  name: 'permission',
  initialState,
  reducers: {

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    setErrorDefault(state) {
      state.error = false;
    },

    // START LOADING
    startAssignment(state) {
      state.isAssigning = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //Newly Added request for Monthly Reports 

    getPermissionListSuccess(state, action) {
      state.isLoading = false;
      state.PermissionList = action.payload;
    },
    getPermissionSuccess(state, action) {
      state.isLoading = false;
      state.Permission = action.payload;
    },
    getUsersPermissionSuccess(state, action) {
      state.isLoading = false;
      state.UserPermissions = action.payload;
    },
    createPermissionSuccess( state,action )
    {
      state.isLoading = false;
      state.createPermissionStatus = action.payload;
    },
    deletePermissionSuccess( state,action )
    {
      state.isLoading = false;
      state.deletePermissionStatus = action.payload;
    },
    updatePermissionSuccess( state,action )
    {
      state.isLoading = false;
      state.updatePermissionStatus = action.payload;
    },
    assignPermissionsToUserSuccess(state,action){
      state.isLoading = false;
      state.assignPermissonSuccess = action.payload
    },
    getPermissionActionsSuccess(state,action){
      state.isLoading = false;
      state.permissionActions = action.payload;
    },
    deletePermissionsForUserSuccess(state,action){
      state.isLoading = false;
      state.deletePermissionsForUserStatus = action.payload;
    },
    getModuleListSuccess(state,action){
      state.isLoading = false;
      state.modulesList = action.payload;
    },
    getAllowedActionsSuccess(state,action){
      state.isLoading = false;
      state.actionsList = action.payload;
    },
    setAllowedActionsList(state,action){
      state.actionsList = action.payload;
    }
  

  }
});






// Reducer
export default slice.reducer;

// Actions
// export const { } = slice.actions;

// ----------------------------------------------------------------------
//Newly Added request for Monthly Reports 


export function getPermissionList( page:number, limit:number) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.get('/Permission/GetAllPermissions',{
            params : {
              page : page,
              limit : limit,
            }
          })
        
          dispatch(slice.actions.getPermissionListSuccess(response.data.result)); 
          
          
    
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }

  export function getPermission(id:any ) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.get(`/Permission/GetPermissionById/${id}`) 
        
          dispatch(slice.actions.getPermissionSuccess(response.data.result));  
          
          
    
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }
  
  
  export function createPermission(data: FormData) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/Permission/CreatePermission`, data);
        if(response?.data?.status === false)
        {
          dispatch(slice.actions.createPermissionSuccess({ status: 'error', error: response?.data }));
  
        }
        else
        dispatch(slice.actions.createPermissionSuccess({ status: 'success', ...response?.data?.result }));
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.createPermissionSuccess({ status: 'error', error: error }));
      }
    };
  }

  export function updatePermission(id:String,data: FormData) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/Permission/UpdatePermission/${id}`, data);
        if(response?.data?.status === false)
        {
          dispatch(slice.actions.updatePermissionSuccess({ status: 'error', error: response?.data }));
  
        }
        else
        dispatch(slice.actions.updatePermissionSuccess({ status: 'success', ...response?.data?.result }));
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.updatePermissionSuccess({ status: 'error', error: error }));
      }
    };
  }

  export function deletePermission(id: String | number) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.delete(`/Permission/DeletePermission`,{
          params:{
            PermissionId:id
          }
        });
        if(response?.data?.status === false) 
        {
          dispatch(slice.actions.deletePermissionSuccess({ status: 'error', error: response?.data }));

        }
        else
          {
            dispatch(slice.actions.deletePermissionSuccess({ status: 'success', ...response?.data?.result }));

          }
      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(error));
        dispatch(slice.actions.deletePermissionSuccess({ status: 'error', error: error }));
      }
    };
  }

  export function getPermissionsByUserId(id:any ) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.get(`/EmployeePermission/GetPermissionByUserId/${id}`) 
        
          dispatch(slice.actions.getUsersPermissionSuccess(response.data.result));  

      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }

  export function assignPermissionsToUser(data:any ) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.post(`/EmployeePermission/AssignPermissionToUser`,data) 
           dispatch(slice.actions.assignPermissionsToUserSuccess(response?.data));

      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }

  export function deletePermissionsForUser(id:any ) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.delete(`/EmployeePermission/DeletePermissionForUser/${id}`) 
          dispatch(slice.actions.deletePermissionsForUserSuccess(response?.data))

      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }

  export function getActionsForPermission( ) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.get(`/EmployeePermission/GetActionLookUp`) 
           dispatch(slice.actions.getPermissionActionsSuccess(response?.data?.result));

      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }

  export function getModuleNames( ) { 
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
  
          const response = await axios.get(`/Menu/ListMenu`) 
           dispatch(slice.actions.getModuleListSuccess(response?.data?.result));

      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }

  export function getAllowedActionsByModuleName(UserId:any,ModuleName:string ) { 
  
    return async () => {

      dispatch(slice.actions.setAllowedActionsList([]))
      dispatch(slice.actions.startLoading());

      try {
          const response = await axios.get(`/Permission/GetModulesActionByUserId`,{
            params:{
              UserId:UserId,
              ModuleName:ModuleName
            }
          }) 

          let result = response?.data?.result;
          let actionsArray = result?.map((data:any)=>data.actionName)
           dispatch(slice.actions.getAllowedActionsSuccess(actionsArray));

      } catch (error) {
        Sentry.captureException(error);
        dispatch(slice.actions.hasError(true));
      }
    };
  }
  



  export function setUpdatePermissionStatus(data:any) {
    return dispatch(slice.actions.updatePermissionSuccess(data))
   }
  
   export function setCreatePermissionStatus(data:any) {
    return dispatch(slice.actions.createPermissionSuccess(data))
   }

   export function setDeletePermissionStatus(data:any) {
    return dispatch(slice.actions.deletePermissionSuccess(data))
   }

   export function setAssignPermissionToUserStatus(data:any) {
    return dispatch(slice.actions.assignPermissionsToUserSuccess(data))
   }

   export function setDeletePermissionForUserStatus(data:any) {
    return dispatch(slice.actions.deletePermissionsForUserSuccess(data))
   }

 


//--------------------------------------------------------------------------------------------

 